import React, { useEffect, useState } from "react";
import { HiMail } from "react-icons/hi";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../Common/Header";
import { getReminderList, reminder } from "../../services/api";
import { validateConsumerReminder, sanitizeValue } from "../../services/validation";
import { showNotification } from "../../services/helper";
import Loader from "../Common/Loader";
import { Pagination } from "@mui/material/";
import { Stack } from "@mui/material/";
import { Tooltip, Zoom } from "@mui/material";
import CustomButton from "../Common/CustomButton";

const tableHeaderStyle = {
	color: "white",
	fontSize: "16px"
}
const columns = [
	{ id: 1, header: "Card Number" },
	{ id: 2, header: "Name" },
	{ id: 3, header: "Date" },
	{ id: 4, header: "Amount" },
	{ id: 5, header: "Status" },
	{ id: 6, header: "Action" },
];
const offset = 0, limit = 10;

const ReminderTable = ({ user }) => {
	const [data, setData] = useState(null);
	const [currentPage, setCurrentPage] = useState(0);
	const { retailerId } = user;

	useEffect(() => {
		_fetchList(offset, limit);
	}, []);

	const _fetchList = async (offset, limit) => {
		try {
			const response = await getReminderList(retailerId, offset, limit);
			if (response.success) {
				setData(response.result);
			}
			else {
				showNotification("error", "Unable to get Reminder List");
				setData({ count: 0, list: [] });
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to get Reminder List");
			setData({ count: 0, list: [] });
		}
	};

	const clickHandler = async item => {

		try {
			handleChangePage(null, 1);
			const form = { ...item };
			form.dueAmountRaw = sanitizeValue(form.totalAmount) - sanitizeValue(form.paidAmount);
			if (validateConsumerReminder(form)) {
				const response = await reminder(form);
				if (response.success) showNotification("success", "Reminder SMS has been sent to consumer.");
				else showNotification("error", "Unable to send reminder SMS.");
			}
			else {
				showNotification("error", "Unable to send reminder");
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to send reminder");
		}
	};

	const handleChangePage = (e, n) => {
		const offset = (n - 1) * limit;
		_fetchList(offset, limit);
		setCurrentPage(n - 1);
	};

	if (!data) return <Loader />;
	return (
		<div className="p-5">
			<Header
				title={`Send Reminder (${sanitizeValue(data?.count)})`}
				showBackButton={true}
				to="-1"
				showReload={true}
				showLogout={true}
				func={() => handleChangePage(null, 1)}
			/>
			<section className="min-w-[95%] max-w-[90%] mx-auto py-10">
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow sx={{
								backgroundColor: "#79c2d0",
							}}>
								{columns.map(head => (
									<TableCell
										sx={tableHeaderStyle}
										align="left"
										key={head.id}
										className="uppercase"
									>
										{head.header}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{data?.list?.map(item => (
								<TableRow key={item.id}>
									<TableCell
										align="left"
										sx={{ fontSize: "12px" }}
										className="uppercase "
									>
										{item.consumerCard}
									</TableCell>
									<TableCell sx={{ fontSize: "12px" }}>{item.consumerName}</TableCell>
									<TableCell align="left" sx={{ fontSize: "12px" }}>{moment(item.createdDate).format("llll")}</TableCell>
									<TableCell align="left" sx={{ fontSize: "12px" }}>₹{item.totalAmount}/-</TableCell>
									<TableCell align="left" sx={{ fontSize: "12px" }}>
										<div
											className={`uppercase text-left font-semibold
													${item.paymentStatus === "partial" && "text-yellow-600"}
													${item.paymentStatus === "pending" && "text-red-600"}
												`}
										>
											{item.paymentStatus}
										</div>
									</TableCell>
									<TableCell
										align="left"
										sx={{ fontSize: "12px" }}
										className="flex flex-col items-center "
									>
										<CustomButton disabled={item?.reminderCount >= 3}>
											<button
												disabled={item?.reminderCount >= 3}
												className={`px-5 py-2 text-xl text-center
												${item?.reminderCount === 0 && "text-blue-500"}
												${item?.reminderCount === 1 && "text-green-500"}
												${item?.reminderCount === 2 && "text-orange-300"}
												${item?.reminderCount === 3 && "text-red-500"}
												
												`}
												onClick={() => clickHandler(item)}
											>
												<HiMail />
											</button>
										</CustomButton>
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</section>
			<div className="py-4">
				<Stack spacing={2} className="flex items-center justify-center">
					<Pagination
						count={Math.ceil(sanitizeValue(data?.count) / limit)}
						variant="outlined"
						shape="rounded"
						showFirstButton
						showLastButton
						color="primary"
						page={currentPage + 1}
						onChange={(e, n) => handleChangePage(e, n)}
					/>
				</Stack>
			</div>

		</div>
	);
};

export default ReminderTable;
