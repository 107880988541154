import React from "react";
import { IoMdChatbubbles } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { Tooltip, Zoom } from "@mui/material";

const ChatBox = () => {
  let navigate = useNavigate();
  return (
    <div className="fixed z-10 flex justify-between px-3 py-4 bottom-8 right-8">
      <Tooltip
        title={<h3 className="text-sm">Message Us</h3>}
        placement="bottom"
        arrow
        TransitionComponent={Zoom}
      >
        <button
          className="flex items-center justify-center bg-white rounded-full shadow-xl w-14 h-14"
          onClick={() => navigate("/admin/support")}
        >
          <IoMdChatbubbles className="text-4xl text-blue-500 " />
        </button>
      </Tooltip>
    </div>
  );
};

export default ChatBox;
