import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { verifyConsumerCard } from "../../../services/api";
import { showNotification } from "../../../services/helper";
import { validateTransaction, isEmpty, checkFloat } from "../../../services/validation";
import { transactionAdd } from "../../../redux/features/transactionSlice";
import Header from "../../Common/Header";
import { useNavigate } from "react-router-dom";
import { FiCreditCard } from "react-icons/fi";

const TransactionAdd = ({ user }) => {
	const { retailerId } = user;
	const [consumer, setConsumer] = useState(null);

	return (
		<>
			<VerifyCard setConsumer={setConsumer} />
			{consumer && <AddForm consumer={consumer} retailerId={retailerId} />}
		</>
	);
};

const VerifyCard = ({ setConsumer }) => {
	const [consumerCard, setConsumerCard] = useState(null);

	const verify = async () => {
		try {
			if (consumerCard && consumerCard.trim().length > 0) {
				const response = await verifyConsumerCard(consumerCard.toUpperCase());
				if (response.success) {
					if (response.result) setConsumer(response.result);
					else {
						setConsumer(null);
						showNotification("error", "Invalid card number");
					}
				}
				else {
					throw new Error();
				}
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to verify card. Please try again after some time.");
		}
	};

	return (
		<>
			<div className="p-5">
				<Header title="Add Transactions" showBackButton={true} to="-1" />
			</div>
			<div className="mx-auto mt-6 px-10 py-10 shadow-md rounded w-[40%] flex flex-col space-y-5">
				<div className="flex items-center space-x-3">
					<FiCreditCard className="text-lg" />
					<label className="text-lg text-center font-md">  Verify Card Number</label>
				</div>
				<TextField size="small" variant="outlined" label="Enter Card Number"
					onChange={e => setConsumerCard(e.target.value)}
					className="min-w-full"
				/>
				<div className="flex justify-center">
					<button onClick={verify} className="px-5 py-2 font-semibold text-white duration-500 rounded hover:bg-blue-500 focus:bg-blue-400 bg-sky-700">
						VERIFY CARD
					</button>
				</div>
			</div>
		</>
	);
};

const AddForm = ({ consumer, retailerId }) => {
	const [form, setForm] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleSubmit = () => {
		const body = {
			consumerId: consumer.id,
			retailerId,
			paymentStatus: form.paymentStatus,
			totalAmount: parseFloat(form.totalAmount)
		}
		if (form.paymentStatus === 'partial') {
			body.paidAmount = parseFloat(form.paidAmount)
		}
		if (validateTransaction(body)) {
			if (form.paymentStatus === 'partial' && isEmpty(form.paidAmount)) {
				showNotification("warning", "Amount Paid cannot be empty for partial payment");
				return;
			}
			if (!checkFloat(form.totalAmount)) {
				showNotification("warning", "Invalid Total Amount");
				return;
			}
			if (form.paymentStatus === 'partial' && !checkFloat(form.paidAmount)) {
				showNotification("warning", 'Invalid Amount Paid');
				return;
			}
			dispatch(transactionAdd(body));
			navigate("/admin/transactions");
		}
		else {
			showNotification("error", "Missing mandatory fields.");
		}
	};

	const handleChange = (field, value) => {
		const obj = { ...form };
		obj[field] = value;
		setForm(obj);
	};


	return (
		(consumer?.status.toLowerCase() === "active" ?
			(<div className="w-[40%] mx-auto mt-8 border-1 border-gray-500 shadow-2xl rounded  py-6 px-12 flex flex-col space-y-4">
				<label>Consumer Name</label>
				<p className="text-base font-semibold">{consumer?.name}</p>
				<select
					placeholder="Payment Status"
					onChange={(e) => handleChange("paymentStatus", e.target.value)}
					className="py-4"
				>
					<option value="">Select Payment Status</option>
					<option value="paid">Paid</option>
					<option value="pending">Pending</option>
					<option value="partial">Partial</option>
				</select>
				<TextField
					variant="outlined" label="Total Amount"
					onChange={(e) => handleChange("totalAmount", e.target.value)}
				/>
				{form.paymentStatus === "partial" &&
					<TextField
						variant="outlined" label="Amount Paid"
						onChange={(e) => handleChange("paidAmount", e.target.value)}
					/>
				}
				<button
					onClick={handleSubmit}
					className="py-4 text-xl text-white duration-700 bg-blue-400 rounded hover:bg-sky-400 focus:bg-blue-700"
				>
					ADD TRANSACTION
				</button>
			</div>) : (
				<div className="py-10 shadow-md  w-[40%] mx-auto mt-4">
					<p className="text-xl font-medium text-center">Card is Inactive</p>
				</div>
			))
	);
}

export default TransactionAdd;
