import { addTransaction, getAllTransactions, updateTransaction, deleteTransaction } from "../../services/api";
import { showNotification } from "../../services/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  hasData: false,
  data: {}
};

export const transactionAdd = createAsyncThunk(
  "transactions/transactionAdd",
  async (transaction, { rejectWithValue, dispatch }) => {
    try {
      const response = await addTransaction(transaction);
      if (response.success) {
        showNotification("success", "New transaction added");
        await dispatch(transactionsGet({ retailerId: transaction.retailerId }));
      }
    }
    catch (error) {
      console.log(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionsGet = createAsyncThunk(
  "transactions/transactionsGet",
  async (params, { rejectWithValue }) => {
    try {
      const { retailerId, offset, limit, startDate, endDate } = params
      const response = await getAllTransactions(retailerId, offset, limit, startDate, endDate);
      return response.success && response.result ? response.result : {};
    }
    catch (error) {
      console.warn(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionDelete = createAsyncThunk(
  "transactions/transactionDelete",
  async (params, { rejectWithValue, dispatch }) => {
    try {
      const response = await deleteTransaction(params.id);
      if (response.success) {
        showNotification("success", "Entity deleted");
        const { retailerId } = params;
        await dispatch(transactionsGet({ retailerId }));
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

export const transactionUpdate = createAsyncThunk(
  "transactions/transactionUpdate",
  async (transaction, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateTransaction(transaction.id, transaction);
      if (response.success) {
        showNotification("success", "Transaction updated");
        const { retailerId } = transaction;
        await dispatch(transactionsGet({ retailerId }));
      }
    } catch (error) {
      console.log(error);
      return rejectWithValue(error.response?.data);
    }
  }
);

const transactionSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {},
  extraReducers: {
    [transactionsGet.fulfilled]: (state, action) => (
      { ...state, hasData: true, data: action.payload, }
    ),
  },
})

export const selectAllTransactions = (state) => state.transactions;
export const selectTransaction = (state) => state.transactions.transaction;

export default transactionSlice.reducer;
