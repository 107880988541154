import React from 'react';
import Lottie from "lottie-react";
import Loading from "./loading.json";

const Loader = () => {
  return (
    <div className='h-screen'>
        <Lottie animationData={Loading} loop={true} style={{height: 400}} />
    </div>
  )
}

export default Loader;