import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Common/Header";
import DashboardCard from "./DashboardCard";
import moment from "moment";
import { dashboardCounts, selectDashboard } from "../../redux/features/dashboardSlice";
import { transactionsGet, selectAllTransactions } from "../../redux/features/transactionSlice";
import { ordersGet, selectAllOrders } from "../../redux/features/ordersSlice";
import { useDispatch, useSelector } from "react-redux";
import { months } from "../../services/helper";
import ChatPopUp from "../ChatPopUp/ChatPopUp";
import Dialog from '@mui/material/Dialog';
import Lottie from "lottie-react";
import comingSoon from "./comingSoon.json"
import Logo from "./yumgeLogo.png"
import { fetchProfile } from "../../redux/features/userSlice";
import CustomCard from "./CustomCard";

const date = new Date();
const currentMonth = months[date.getMonth()];
const limit = 1000;

const Dashboard = ({ user }) => {
	const { retailerId } = user;
	const [monthTransactionAmount, setMonthTransactionAmount] = useState(0);
	const [monthTransactionCount, setMonthTransactionCount] = useState(0);
	const [monthOrderAmount, setMonthOrderAmount] = useState(0);
	const [monthOrderCount, setMonthOrderCount] = useState(0);
	const [transactionsList, setTransactionslist] = useState([]);
	const [ordersList, setOrdersList] = useState([]);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const dashboard = useSelector(selectDashboard);
	const transactions = useSelector(selectAllTransactions);
	const orders = useSelector(selectAllOrders);
	const { profile, data } = useSelector(state => state.user);

	useEffect(() => {
		if (!dashboard.hasData) dispatch(dashboardCounts(retailerId));
	}, []);

	useEffect(() => {
		if (!transactions.hasData) dispatch(transactionsGet({ retailerId,limit }));
		else _getMonthTransaction(transactions.data || []);
	}, [transactions]);

	useEffect(() => {
		if (!orders.hasData) dispatch(ordersGet({ retailerId,limit }));
		else _getMonthOrder(orders.data || []);
	}, [orders]);
	useEffect(() => {
		if (!profile) dispatch(fetchProfile(data.retailerId));
		else return [];
	}, [profile]);



	const reload = () => {
		dispatch(dashboardCounts(retailerId))
		dispatch(transactionsGet({ retailerId }))
	};

	const _getMonthTransaction = transactions => {
		let amount = 0, count = 0;
		for (let item of transactions?.list) {
			let itemDate = new Date(item.createdDate);
			if (itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear()) {
				amount += item.totalAmount;
				count += 1;
			}
		}
		setMonthTransactionAmount(amount);
		setMonthTransactionCount(count);
		setTransactionslist(transactions?.list?.slice(0, 3));
	};

	const _getMonthOrder = orders => {
		let amount = 0, count = 0;
		for (let item of orders?.list) {
			let itemDate = new Date(item.createdDate);
			if (itemDate.getMonth() === date.getMonth() && itemDate.getFullYear() === date.getFullYear() && item.status !== "cancelled") {
				amount += item.totalAmount;
				count += 1;
			}
		}
		setMonthOrderAmount(amount);
		setMonthOrderCount(count);
		setOrdersList(orders?.list?.slice(0, 3));
	};
	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div className="relative w-full p-5">
			<Header title="Dashboard" showLogout={true} showReload={true} func={() => reload()} />
			<div className="flex flex-col w-full pt-4 pb-8">
				<div className="flex space-x-5">

					<button onClick={handleClickOpen} className="px-2 py-2 font-bold  text-white bg-[#a393eb] hover:bg-[#f5c7f7] rounded duration-500">
						My e-commerce
					</button>

					<button
						className="px-4 py-2 font-bold text-white bg-purple-500 rounded duration-500 hover:bg-[#ebfb]"
						onClick={() => navigate("/admin/inventory")}
					>
						My Inventory
					</button>
				</div>
				<div></div>
			</div>
			<div className="w-[100%] grid lg:grid-cols-3 gap-y-3 md:grid-cols-2 lg:pt-2 sm:pt-[10%]">
				<DashboardCard title="Total Transactions " value={dashboard?.data?.transactionCount?.count} />
				<DashboardCard title={`${currentMonth} Transactions`} value={monthTransactionCount} />
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <DashboardCard title="Total Orders" value={dashboard?.data?.orderCount?.count} />}
				<DashboardCard title="Total Transactions Value" value={`₹ ${dashboard?.data?.transactionCount?.totalTransactionAmount}`} />
				<DashboardCard title={`${currentMonth} Transactions Value`} value={`₹ ${monthTransactionAmount}`} />
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <DashboardCard title="Total Order Value" value={`₹ ${dashboard?.data?.orderCount?.totalOrderAmount}`} />}
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <DashboardCard title={`${currentMonth} Orders`} value={monthOrderCount} />}
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <CustomCard user={user} title={`${currentMonth} Order Value`} value={monthOrderAmount} />}
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <DashboardCard title="Bonus Point" value={profile?.bonus} />}
				{profile?.storeType.toLowerCase() === "electrical & hardware" && <DashboardCard title="Bonus Scheme" value={profile?.bonusScheme} />}
				<DashboardCard title="Right2Choice UPI" value="care.right2choice@okhdfcbank" />
			</div>
			<div>
				<h4 className="mt-8 mb-4 ml-4 font-bold ">
					Recent Transactions
					<button
						className="px-3 py-1 ml-3 text-sm font-medium text-white bg-blue-400 rounded-lg "
						onClick={() => navigate("/admin/transactions")}
					>
						VIEW ALL
					</button>
				</h4>

				<div className="flex flex-wrap w-full gap-4">
					{transactionsList?.map((item) => (
						<div key={item.id} className="text-sm font-semibold">
							<div className="flex flex-col w-[90%]   max-w-[400px] min-w-[350px] px-6 py-4 bg-white shadow-md rounded-xl">
								<p>{item.consumerName}</p>
								<p> <span className="text-slate-500">Total :</span>  ₹ {item.totalAmount}/-</p>
								<p className={`uppercase ${item.paymentStatus === "paid" && "text-green-600"} ${item.paymentStatus === "pending" && "text-red-600"} ${item.paymentStatus === "partial" && "text-blue-600"}`}> <span className="text-slate-500">Payment Status :</span> {item.paymentStatus}</p>
								<p>{moment(new Date(item.createdDate)).format("DD/MM/YYYY")}</p>
							</div>
						</div>
					))}
				</div>

			</div>
			{
				profile?.storeType.toLowerCase() === "electrical & hardware" &&
				<div>
					<h4 className="mt-8 mb-4 ml-4 font-bold">Recent Orders
						<button className="px-3 py-1 ml-1 text-sm font-medium text-white bg-blue-400 rounded-lg "
							onClick={() => navigate("/admin/orders")}
						>
							VIEW ALL
						</button>
					</h4>
					<div className="flex flex-wrap w-full gap-4">
						{ordersList?.map((item) => (
							<div key={item.id} className="text-sm font-semibold ">
								<div className="flex flex-col w-[90%] max-w-[400px] min-w-[350px] px-6 py-4 bg-white shadow-md rounded-xl">
									<p className="uppercase"> <span className="text-slate-500"> Order ID: </span> {item.id}</p>
									<p className={`${item.status === "active" && "text-green-600"} ${item.status === "cancelled" && "text-red-600"} uppercase`}> <span className="text-slate-500">Order Status:</span>  {item.status}</p>
									<p className={`${item.paymentStatus === "paid" && "text-green-600"} ${item.paymentStatus === "pending" && "text-red-600"} ${item.paymentStatus === "cancelled" && "text-orange-600"} uppercase`}> <span className="text-slate-500">Payment Status: </span> {item.paymentStatus}</p>
									<p>{moment(new Date(item.createdDate)).format("DD/MM/YYYY")}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			}
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					sx={{
						margin: 0,
						padding: 0
					}}
				>
					<div className="flex flex-col items-center justify-between">
						<img src={Logo} alt="logo" className="object-contain object-center w-1/2 h-1/2" />
						<Lottie animationData={comingSoon} loop={true} style={{ height: 320 }} isStopped={false}
							isPaused={false}
							speed={2} />
					</div>
					<div className="flex items-center justify-center py-2">

						<button className="flex justify-center px-6 py-2 text-white bg-blue-500 rounded" onClick={handleClose} autoFocus>
							OK
						</button>
					</div>


				</Dialog>
			</div>
			<ChatPopUp ShowChatPopUp={true} />
		</div>
	);
};

export default Dashboard;
