import { IconButton, Tooltip, Zoom } from '@mui/material';
import React from 'react';
import {IoReload} from "react-icons/io5";

const Reload = ({func}) => {

  return (
    <div className='flex items-center justify-center ml-2 w-max'>
		<Tooltip title={<h3 className="text-sm">Reload</h3>} placement="bottom" arrow TransitionComponent={Zoom}>
			<IconButton onClick={func}>
				<IoReload />
			</IconButton>
		</Tooltip>
    </div>
  )
}

export default Reload;