import React, { useState,useEffect } from "react";
import { Routes, Route, NavLink, Navigate } from "react-router-dom";
import { MdDashboard, MdSupportAgent } from "react-icons/md";
import { BsBagFill } from "react-icons/bs";
import { IoMdCart } from "react-icons/io";
import { BsFillPersonFill } from "react-icons/bs";
import { MdOutlineArrowBackIos } from "react-icons/md";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import Dashboard from "../components/Dashboard/Dashboard";
import TransactionPage from "./TransactionPage";
import Tooltip from "@mui/material/Tooltip";
import Zoom from "@mui/material/Zoom";
import ProfileSetting from "../components/Profile/ProfileSetitng";
import ReminderTable from "../components/TransactionData/ReminderTable";
import PayoutTable from "../components/TransactionData/PayoutTable";
import Orders from "../components/OrderPage/Orders"
import OrderAdd from "../components/OrderPage/OrderAdd";
import Inventory from "../components/Inventory/Inventory";
import InventoryUpdate from "../components/Inventory/InventoryUpdate";
import TransactionAdd from "../components/TransactionData/TransactionAdd/TransactionAdd";
import TransactionUpdate from "../components/TransactionData/TransactionUpdate/TransactionUpdate";
import { useNavigate } from "react-router-dom";
import PageNotFound from "./PageNotFound";
import Loader from "../components/Common/Loader";
import Support from "../components/Common/Support";
import ProfileImage from "../components/ProfileImageUploader/ProfileImage";


const links = [
	{ id: 1, link: "/admin", icon: <MdDashboard />, label: "Dashboard" },
	{ id: 2, link: "/admin/transactions", icon: <BsBagFill />, label: "Transactions" },
	{ id: 3, link: "/admin/orders", icon: <IoMdCart />, label: "Orders" },
	{ id: 4, link: "/admin/profile", icon: <BsFillPersonFill />, label: "Profile" },
	{ id: 5, link: "/admin/support", icon: <MdSupportAgent />, label: "Support" },
		
];
const links2 = [
	{ id: 1, link: "/admin", icon: <MdDashboard />, label: "Dashboard" },
	{ id: 2, link: "/admin/transactions", icon: <BsBagFill />, label: "Transactions" },
	{ id: 3, link: "/admin/profile", icon: <BsFillPersonFill />, label: "Profile" },
	{ id: 4, link: "/admin/support", icon: <MdSupportAgent />, label: "Support" },
		
];

const AdminPage = ({ user }) => {

	const [isLoading,setIsLoading] = useState(true);
	const navigate = useNavigate();
	const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
	
	useEffect(() => {
		if(user && user.retailerId) setIsLoading(false);
		else navigate("/");
	},[user]);
	
	if(isLoading) return <Loader />;
	return (
		<>
		<div className="relative flex w-full ">
			<div
				className={`${isSidebarCollapsed ? "w-[80px]" : "w-[180px]"} flex flex-col min-h-screen sticky top-0 z-10 border-r-2 divide-y-2 max-h-screen overflow-y-auto`}
			>
				<div className="flex items-center justify-center w-full p-2">
					{isSidebarCollapsed ? (
						<div>
							<button onClick={() => navigate("/admin")}>
								<img
									src="/images/logo.png"
									alt="logo"
									className="object-cover object-center" />
							</button>
						</div>
					) : (
						<div>
							<button
								onClick={() => navigate("/admin")}
								className="flex flex-col items-center">
								<img
									src="/images/logo.png"
									alt="logo"
									className="w-[90px] h-[90px] object-center" />
								<h2 className="text-lg font-bold text-center text-[#881337] font-sans">
									{user.storeName}
								</h2>
							</button>
						</div>
					)}
				</div>
				
				<div className="flex-auto w-full py-6">
					{
						user?.storeType.toLowerCase() === "electrical & hardware" ?
						<ul className="flex flex-col space-y-1 font-semibold text-md">
						{
							links.map((nav) => (
								<NavLink
									key={nav.id}
									to={nav.link}
									end
									className={({ isActive }) => isActive ? "text-[#3C4DE5] bg-slate-100" : undefined}
								>
									{isSidebarCollapsed ? (
										<Tooltip
											title={<h3 className="text-base">{nav.label}</h3>}
											placement="right"
											arrow
											TransitionComponent={Zoom}
											followCursor
										>
											<li className="pr-navlink-style-collapsed">{nav.icon}</li>
										</Tooltip>
									) : (
										<li className="pr-navlink-style ">
											{nav.icon}
											<p>{nav.label}</p>
										</li>
									)}
								</NavLink>
							))
						} 
					</ul> :
					<ul className="flex flex-col space-y-1 font-semibold text-md">
					{
						links2.map((nav) => (
							<NavLink
								key={nav.id}
								to={nav.link}
								end
								className={({ isActive }) => isActive ? "text-[#3C4DE5] bg-slate-100" : undefined}
							>
								{isSidebarCollapsed ? (
									<Tooltip
										title={<h3 className="text-base">{nav.label}</h3>}
										placement="right"
										arrow
										TransitionComponent={Zoom}
										followCursor
									>
										<li className="pr-navlink-style-collapsed">{nav.icon}</li>
									</Tooltip>
								) : (
									<li className="pr-navlink-style ">
										{nav.icon}
										<p>{nav.label}</p>
									</li>
								)}
							</NavLink>
						))
					} 
				</ul>
					}
					
				</div>
				<div className="w-full">
					<button
						className="pr-navlink-toggle-btn "
						onClick={() => setIsSidebarCollapsed((prev) => !prev)}
					>
						{isSidebarCollapsed ? (
							<MdOutlineArrowForwardIos />
						) : (
							<MdOutlineArrowBackIos />
						)}
					</button>
				</div>
			</div>
			<div
				className={`bg-[#F7F9FC] overflow-x-hidden ${isSidebarCollapsed ? "w-[calc(100%-80px)]" : "w-[calc(100%-180px)]"} min-h-screen`}
			>
				<Routes>
					<Route index element={<Dashboard user={user} />} />
					<Route path="transactions" element={<TransactionPage user={user} />} />
					<Route path="orders" element={user?.storeType.toLowerCase() === "electrical & hardware" ? <Orders user={user} /> : <Navigate replace to={"/admin"} />} />
					<Route path="profile" element={<ProfileSetting user={user} />} />
					<Route path="profile/image" element={<ProfileImage user={user} />} />
					<Route path="transactions/reminder" element={<ReminderTable user={user} />} />
					<Route path="transactions/payout" element={<PayoutTable user={user} />} />
					<Route path="orders/add" element={user?.storeType.toLowerCase() === "electrical & hardware" ? <OrderAdd user={user} /> : <Navigate replace to={"/admin"} />} />
					<Route path="/inventory" element={<Inventory user={user} />} />
					<Route path="/inventory/edit" element={<InventoryUpdate user={user} />} />
					<Route path="/transactions/add" element={<TransactionAdd user={user} />} />
					<Route path="/support" element={<Support user={user} />} />
					<Route path="/transactions/:id/edit/" element={<TransactionUpdate user={user} />} />
					<Route path="*" element={<PageNotFound />} />
				</Routes>
			</div>
		</div> 
		<div>
		
		</div>
		</>
	);
};

export default AdminPage;
