// import { showNotification } from "../../services/helper";
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getStocks,updateStock } from "../../services/api";
import { showNotification } from "../../services/helper";

const initialState = {
    hasData: false,
    data: {}
};
 
export const stocksGet = createAsyncThunk(
    "stocks/stocksGet",
    async (id, { rejectWithValue }) => {
        try {
            const response = await getStocks(id);
            if(response.success) return response.result;
            else return [];            
        }
        catch(error){
            console.warn(error);
            return rejectWithValue(error.response?.data);
        }
    }
);

export const stocksUpdate = createAsyncThunk(
	"stocks/stocksUpdate",
	async (form, { rejectWithValue,dispatch }) => {
		try {
			const response = await updateStock(form.id,form);
			if(response.success){
				showNotification("success", "Stock updated");
				await dispatch(stocksGet(form.retailerId));
			}
		}
        catch(error){
			console.warn(error);
			return rejectWithValue(error);
		}
	}
);
  
const stocksSlice = createSlice({
    name: 'stocks',
    initialState,
    reducers: {},
    extraReducers: {
    
    [stocksGet.fulfilled]: (state,action) => {
      return {
        ...state,
        hasData:true,
        data: action.payload,
      };
    },
  },
})

export const selectAllStocks = (state) => state.stocks;
export default stocksSlice.reducer
