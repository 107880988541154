import React,{ useEffect,useState } from "react";
import { BrowserRouter as Router,Routes,Route } from "react-router-dom";
import AdminPage from "./pages/AdminPage"
import LoginPage from "./pages/LoginPage"
import PageNotFound from "./pages/PageNotFound";
import Loader from "./components/Common/Loader";
import { getItem } from "./services/helper";
import { useDispatch,useSelector } from "react-redux";
import { userActions } from "./redux/features/userSlice";
import { setAuthHeader } from "./services/api";

const App = () => {
    const [isLoading,setIsLoading] = useState(true);
    const user = useSelector(state => state.user);
    const dispatch = useDispatch();

    useEffect(() => {
        const credentials = getItem("credentials");
        if(credentials && credentials.token){
            dispatch(userActions.update(credentials));
            setAuthHeader(credentials.token);
        }
        setIsLoading(false);
    },[]);

    if(isLoading) return <Loader />;
    return (
        <Router>
            <Routes>
                <Route path="/" element={<LoginPage user={user.data} />} />
                <Route path="/admin/*" element={<AdminPage user={user.data} />} />
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </Router>
    )
};

export default App;
