import React, { useState, useEffect } from "react";
import { TextField } from "@mui/material";
import { getOTP, verifyOTP, setAuthHeader } from "../services/api";
import { isEmpty, validateMobile } from "../services/validation";
import { showNotification, setItem } from "../services/helper";
import { useDispatch } from "react-redux";
import { userActions } from "../redux/features/userSlice";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import screen from "./screen.json"

const style = {
	height: 500,
};

const LoginPage = ({ user }) => {
	const navigate = useNavigate();
	const [mobile, setMobile] = useState();
	const [step, setStep] = useState(1);
	const [otp, setOtp] = useState();
	const [data, setData] = useState({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (user && user.retailerId) {
			navigate("/admin");
		}
	}, [user]);

	const otpHandler = async () => {
		try {
			if (!validateMobile(mobile)) {
				showNotification("error", "Invalid Mobile Number");
				return;
			}
			const response = await getOTP({ mobile });
			if (response?.success) {
				setStep(2);
				const { message, retailerId, storeName, storeType, storeCategory, bonusScheme } = response.result;
				setData({ retailerId, storeName, storeType, storeCategory, bonusScheme });
				showNotification("success", message);
			}
			else {
				showNotification("warning", response?.message);
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to get OTP");
		}
	};

	const verifyHandler = async () => {
		try {
			if (!isEmpty(otp) && otp.length === 4 && !isNaN(otp / 4)) {
				const body = { userId: data.retailerId, otp };
				const response = await verifyOTP(body);
				if (response?.success) {
					const token = response.result;
					const user = { ...data, token };
					setItem("credentials", user);
					dispatch(userActions.update(user));
					setAuthHeader(token);
					navigate("/admin");
				}
				else {
					showNotification("warning", "Invalid OTP");
				}
			}
			else {
				showNotification("error", "Invalid OTP");
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to log in.");
		}
	};

	return (
		<>
			<section className="flex ">
				<div className="w-[30%] min-h-screen border-r border-gray-500">
					<div className="flex items-center justify-center mx-auto mt-10">

						<img src="/images/logo.png" alt="" className="w-[40%] h-[40%] object-contain object-center" />
					</div>

					<div className="max-w-[80%]  mx-auto pt-10">
						{step === 1 &&
							<StepOne
								setMobile={setMobile}
								otpHandler={otpHandler}
							/>
						}
						{step === 2 &&
							<StepTwo
								setStep={setStep}
								setOtp={setOtp}
								verifyHandler={verifyHandler}
							/>
						}
					</div>
					<div className="relative">
						<h3 className="absolute text-base font-medium -bottom-60 left-[30%] ">Engineered@CodeXP</h3>
					</div>
				</div>


				<div className="w-[70%] min-h-screen bg-violet-300">
					{/* <img
						src="/images/logo.png"
						className="object-contain object-top w-1/2 h-screen "
						alt=""
					/> */}
					<div className="flex items-center justify-center w-full h-screen">
						<Lottie animationData={screen} loop={true}
							style={style}
						/>
					</div>
				</div>
			</section>

		</>
	);
};

const StepOne = ({ setMobile, otpHandler }) => {
	return (
		<div className="">
			<div className="py-4">

				<h1 className="text-xl font-bold">Login</h1>
				<p className="text-base font-normal text-slate-500">Please Enter Your Registered Mobile Number To Login</p>
			</div>

			<TextField
				variant="filled"
				label="Enter Your Phone Number"
				type="text"
				className="w-[100%]"
				onChange={(e) => setMobile(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						otpHandler()

					}
				}}
			/>
			<div className="flex flex-col items-center justify-center mt-4">
				<button
					className="px-20 py-3 text-white bg-sky-400 rounded-xl hover:bg-sky-600"
					type="submit"
					onClick={otpHandler}
				>
					Get OTP
				</button>
			</div>
		</div>
	);
};

const StepTwo = ({ setOtp, verifyHandler, setStep }) => {
	return (
		<div className="px-8">
			<label className="text-sm text-left text-gray-500">
				Enter OTP
			</label>
			<TextField
				variant="outlined"
				label="Enter OTP"
				type="text"
				className="w-[100%] my-5 "
				onChange={(e) => setOtp(e.target.value)}
				onKeyPress={(e) => {
					if (e.key === "Enter") {
						verifyHandler()
					}
				}}
			/>
			<div className="flex space-x-5">
				<button
					onClick={() => setStep(1)}
					type="button"
					className="w-[50%] py-4 mt-10 text-white bg-orange-400 rounded-xl hover:bg-orange-600 focus:bg-orange-600"
				>
					BACK
				</button>
				<button
					type="button"
					onClick={verifyHandler}
					className="w-[50%] py-4 mt-10 text-white bg-blue-400 rounded-xl hover:bg-blue-600 focus:bg-blue-600"
				>
					VERIFY OTP
				</button>
			</div>
		</div>
	);
}

export default LoginPage;
