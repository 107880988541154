import React from 'react';
import { getBonusLimit } from "../../services/helper";

export default function CustomCard({ title,value,user }) {
    return (
        <div className='pr-dashboard-grid-item'>
            <p className='font-semibold text-gray-600 text-md'>{title}</p>
            <p className={`font-semibold ${parseInt(value) <= getBonusLimit(user.scheme) ? "text-red-600" : "text-green-600"}`}>₹ {value?.toFixed(2)}</p>
        </div>
    )
}
