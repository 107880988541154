import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { stocksGet } from "../../redux/features/stockSlice";
import { Stack } from "@mui/material/";
import { selectAllStocks } from "../../redux/features/stockSlice";
import { Pagination } from "@mui/material/";
import Header from "../Common/Header";
import { useNavigate } from "react-router-dom";
import Loader from "../Common/Loader";
import { sanitizeValue } from "../../services/validation";

const Inventory = ({ user }) => {
    const limit = 24;
    const [currentPage, setCurrentPage] = useState(0);
    const stocks = useSelector(selectAllStocks);
    const dispatch = useDispatch();
    const { retailerId } = user;
    const navigate = useNavigate();

    useEffect(() => {
        if (!stocks.hasData) dispatch(stocksGet(retailerId));
    }, []);

    const handleChangePage = (e, n) => setCurrentPage(n - 1);


    if (!stocks.hasData) return <Loader />;

    const arr = stocks?.data?.list[0]?.items
        ?.slice(limit * currentPage, limit * currentPage + limit)
        .map((item) => (
            <div key={item.id} className="flex items-center justify-between p-4 bg-white shadow-xl rounded-xl">
                <div>
                    <p className="font-sans text-sm uppercase">{item?.name}</p>
                    <p className="text-sm font-medium text-gray-600">{item?.count} {item?.unit}</p>
                </div>
            </div>
        ));

    return (
        <>
            <section className="p-5">
                <Header title={`Total Items: ${sanitizeValue(stocks?.data?.list[0]?.items?.length)}`}
                    showBackButton={true} to="-1" />

                <div className="flex px-6">
                    <button className="px-8 py-2 mt-5 text-sm font-medium text-white duration-300 bg-blue-600 rounded hover:bg-blue-500" type="button" onClick={() => navigate("/admin/inventory/edit")}>Edit</button>
                </div>

            </section>
            <section className="grid grid-cols-3 gap-4 p-4 ">{arr}</section>
            <div className="py-5">
                <Stack spacing={2} className="flex items-center justify-center">
                    <Pagination
                        count={Math.ceil(stocks?.data?.list[0]?.items?.length / limit)}
                        variant="outlined"
                        shape="rounded"
                        showFirstButton
                        showLastButton
                        color="primary"
                        page={currentPage + 1}
                        onChange={(e, n) => handleChangePage(e, n)}
                    />
                </Stack>
            </div>
        </>
    );
};

export default Inventory;
