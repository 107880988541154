import { useState, useEffect, useRef } from "react";
import { IoIosTimer, IoMdWallet } from "react-icons/io";
import { HiMail } from "react-icons/hi";
import { Tooltip, Zoom } from "@mui/material";
import { Pagination } from "@mui/material/";
import { Stack } from "@mui/material/";
import {
    BsCalendarEvent,
    BsCashStack,
    BsCheck2Circle,
    BsFileEarmarkExcel,
    BsFillCreditCard2BackFill,
    BsFillPersonFill,
    BsPlusLg,
} from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import CustomModal from "../CustomModal/CustomModal";
import { useSelector, useDispatch } from "react-redux";
import {
    transactionsGet,
    selectAllTransactions,
} from "../../redux/features/transactionSlice";
import moment from "moment";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { DownloadTableExcel } from "react-export-table-to-excel";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Header from "../Common/Header";
import { sanitizeValue, validateFilter } from "../../services/validation";
import { months, numberOfDays, showNotification, years, getStartDate, getEndDate } from "../../services/helper";

const iconButtonStyle = `flex items-center justify-center 
text-md text-white bg-blue-700 px-4 py-2 rounded-xl
hover:text-blue-100 hover:bg-sky-400 duration-500`;
const cardRowStyle = "flex items-center space-x-5";
const cardLabelText = "text-xs text-gray-500";
const cardValueText = "text-sm font-semibold";
const selectStyle = "px-8 py-1 text-sm font-semibold border border-gray-400 rounded-xl";
const optionStyle = "text-base font-medium";
const tableHeaderStyle = {
    color: "white",
    fontSize: "16px"
}
const limit = 20;
const offset = 0;
const initialState = {
    year: "",
    month: "",
};


const TransactionTable = ({ user }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const tableRef = useRef(null);
    const [form, setForm] = useState({ ...initialState });
    const { retailerId } = user;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const transactions = useSelector(selectAllTransactions);
    const [open, setOpen] = useState(false);
    const [activeTransaction, setActiveTransaction] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!transactions.hasData) dispatch(transactionsGet({ retailerId, offset, limit }));
    }, []);

    const handleOpen = (transaction) => {
        setActiveTransaction(transaction);
        setOpen(true);
    };

    const handleChangePage = (e, n) => {
        let params = { retailerId, offset: (n - 1) * limit, limit };
        if (validateFilter(form)) {
            params = {
                ...params,
                startDate: getStartDate(form.month, form.year),
                endDate: getEndDate(form.month, form.year)
            };
        }
        dispatch(transactionsGet(params));
        setCurrentPage(n - 1);
    };

    const handleChange = (type, data) => {
        const obj = { ...form };
        obj[type] = data;
        setForm(obj);

    };

    const refresh = () => {
        dispatch(transactionsGet({ retailerId, offset, limit }));
        setForm({ ...initialState });
        setCurrentPage(0);
    }

    const handleSearch = async () => {
        try {
            if (validateFilter(form)) {
                setIsLoading(true);
                const startDate = getStartDate(form.month, form.year);
                const endDate = getEndDate(form.month, form.year);
                dispatch(transactionsGet({ retailerId, offset, limit, startDate, endDate }));
                setCurrentPage(0);
            }
            else showNotification("warning", "Filters cannot be empty");
        }
        catch (err) {
            console.warn(err);
            showNotification("error", "Unable to get payouts");
        }
        finally {
            setIsLoading(false);
        }
    };
    const arr = transactions?.data?.list?.map((item) => {
        return (
            <TableRow key={item.id}>
                <TableCell
                    align="left"
                    sx={{ fontSize: "14px" }}
                    className="uppercase"
                >
                    {item.id}
                </TableCell>
                <TableCell sx={{ fontSize: "14px" }}>{item.consumerName}</TableCell>
                <TableCell align="left" sx={{ fontSize: "14px" }}>
                    {moment(item.createdDate).format("llll")}
                </TableCell>
                <TableCell align="left" sx={{ fontSize: "14px" }}>
                    ₹ {item.totalAmount}/-
                </TableCell>
                <TableCell align="left">
                    <div
                        className={`uppercase font-semibold text-[12px]
                                    ${item.paymentStatus === "paid" && "text-green-500"}
                                    ${item.paymentStatus === "pending" && "text-red-600"}
                                    ${item.paymentStatus === "partial" && "text-blue-600"}
                                `}
                    >
                        {item.paymentStatus}
                    </div>
                </TableCell>
                <TableCell align="left" className="flex flex-col items-center ">
                    <button
                        className="px-2 py-1 text-[12px] text-center text-white bg-blue-600 rounded-lg hover:bg-blue-600"
                        onClick={() => handleOpen(item)}
                    >
                        VIEW
                    </button>
                </TableCell>
            </TableRow>
        );
    });

    return (
        <>
            <section className="w-full ">
                <div className="p-5">
                    <Header
                        title={`Transactions: ${sanitizeValue(transactions?.data?.count)}`}
                        showReload={true}
                        func={refresh}
                        showLogout={true}
                    />
                </div>
                <div className="w-full py-2 ">
                    <div className="flex justify-between px-5">
                        <div className="flex items-center justify-center space-x-4 ">
                            <div>
                                <DownloadTableExcel
                                    filename="Transaction"
                                    sheet="TransactionTable"
                                    currentTableRef={tableRef.current}
                                >
                                    <button className="flex items-center justify-center px-3 py-2 text-xs text-white bg-green-600 focus:bg-green-500 hover:bg-green-400 rounded-xl">
                                        <BsFileEarmarkExcel className="mr-2" /> Download in Excel
                                    </button>
                                </DownloadTableExcel>
                            </div>
                            <div className="flex pl-10 space-x-10">
                                <select
                                    onChange={(e) => handleChange("month", e.target.value)}
                                    className={selectStyle}
                                    value={form.month}
                                >
                                    <option value="" className={optionStyle}>Select Month</option>
                                    {months.map((item, index) => (
                                        <option key={item} value={index} className="text-base font-medium">{item}</option>
                                    ))}
                                </select>
                                <select
                                    onChange={(e) => handleChange("year", e.target.value)}
                                    className={selectStyle}
                                    value={form.year}
                                >
                                    <option value="" className={optionStyle}>Select Year</option>
                                    {years.map(item => (
                                        <option key={item} value={item} className="text-base font-medium">{item}</option>
                                    ))}
                                </select>
                                <button className={iconButtonStyle} onClick={handleSearch}>Search</button>
                            </div>
                        </div>
                        {/* BUTTON */}
                        <div className="flex items-center space-x-4 ">
                            <Tooltip
                                title={<h3 className="text-sm">Reminder</h3>}
                                placement="bottom"
                                arrow
                                TransitionComponent={Zoom}
                            >
                                <Link className={iconButtonStyle} to="reminder"><HiMail /></Link>
                            </Tooltip>
                            <Tooltip
                                title={<h3 className="text-sm">PayOut</h3>}
                                placement="bottom" arrow TransitionComponent={Zoom}
                            >
                                <Link className={iconButtonStyle} to="payout">
                                    <IoMdWallet />
                                </Link>
                            </Tooltip>
                            <Tooltip
                                title={<h3 className="text-sm">Add Transaction</h3>}
                                placement="bottom" arrow TransitionComponent={Zoom}
                            >
                                <Link className={iconButtonStyle} to="/admin/transactions/add">
                                    <BsPlusLg />
                                </Link>
                            </Tooltip>
                        </div>
                    </div>
                </div>
                {/* TABLE */}
                <section className="px-3 mx-auto mt-8 ">
                    <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 650 }} size="small">
                            <TableHead>
                                <TableRow sx={{
                                    backgroundColor: "#8f71ff",
                                }}>
                                    <TableCell align="left" sx={tableHeaderStyle}>ID</TableCell>
                                    <TableCell align="left" sx={tableHeaderStyle}>Name</TableCell>
                                    <TableCell align="left" sx={tableHeaderStyle}>Date & Time</TableCell>
                                    <TableCell align="left" sx={tableHeaderStyle}>Amount</TableCell>
                                    <TableCell align="left" sx={tableHeaderStyle}>Status</TableCell>
                                    <TableCell align="left" sx={tableHeaderStyle}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody >


                                {transactions?.data?.count === 0 ? <TableCell sx={{
                                    padding: 5,
                                    fontSize: "15px"
                                }}>No Data Available</TableCell> : arr}

                            </TableBody>
                        </Table>
                    </TableContainer>
                </section>
                <div className="py-4">
                    <Stack spacing={2} className="flex items-center justify-center">
                        <Pagination
                            count={Math.ceil(sanitizeValue(transactions?.data?.count) / limit)}
                            variant="outlined"
                            shape="rounded"
                            showFirstButton
                            showLastButton
                            color="primary"
                            page={currentPage + 1}
                            onChange={(e, n) => handleChangePage(e, n)}
                        />
                    </Stack>
                </div>
                {/* MODAL */}
                <div>
                    <CustomModal open={open} setOpen={setOpen}>
                        <div className="py-[5%] text-sm">
                            <div className="flex flex-col pl-8 space-y-2 max-h-fit">
                                <div className={cardRowStyle}>
                                    <AiOutlineExclamationCircle className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Transaction ID</label>
                                        <p className={cardValueText}>{activeTransaction?.id}</p>
                                    </div>
                                </div>
                                <div className={cardRowStyle}>
                                    <BsFillCreditCard2BackFill className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Card Number</label>
                                        <p className={cardValueText}>
                                            {activeTransaction?.consumerCard}
                                        </p>
                                    </div>
                                </div>
                                <div className={cardRowStyle}>
                                    <BsFillPersonFill className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Name</label>
                                        <p className={cardValueText}>
                                            {activeTransaction?.consumerName}
                                        </p>
                                    </div>
                                </div>
                                <div className={cardRowStyle}>
                                    {activeTransaction?.paymentStatus === "paid" ? (
                                        <BsCheck2Circle className="text-xl text-green-600" />
                                    ) : (
                                        <IoIosTimer className="text-xl text-red-600" />
                                    )}
                                    <div>
                                        <label className={cardLabelText}>Payment Status</label>
                                        <p className={`${cardValueText} uppercase`}>
                                            {activeTransaction?.paymentStatus}
                                        </p>
                                    </div>
                                </div>
                                <div className={cardRowStyle}>
                                    <BsCashStack className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Total Amount</label>
                                        <p className={cardValueText}>
                                            ₹ {activeTransaction?.totalAmount}/-
                                        </p>
                                    </div>
                                </div>
                                {activeTransaction?.paymentStatus === "partial" && (
                                    <div className={cardRowStyle}>
                                        <BsCashStack className="text-xl" />
                                        <div>
                                            <label className={cardLabelText}>Amount Paid</label>
                                            <p className={cardValueText}>
                                                ₹ {activeTransaction?.paidAmount}/-
                                            </p>
                                        </div>
                                    </div>
                                )}
                                <div className={cardRowStyle}>
                                    <BsCalendarEvent className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Transaction Date</label>
                                        <p className={cardValueText}>
                                            {moment(activeTransaction?.createdDate).format("llll")}
                                        </p>
                                    </div>
                                </div>
                                <div className={cardRowStyle}>
                                    <BsCalendarEvent className="text-xl" />
                                    <div>
                                        <label className={cardLabelText}>Reason for update</label>
                                        <p className="text-base font-semibold">
                                            {activeTransaction?.reason}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="flex items-center my-6 justify-evenly ">
                                <button
                                    className="px-8 py-2 font-semibold text-center text-white bg-blue-600 rounded-xl"
                                    onClick={() => navigate(`/admin/transactions/${activeTransaction.id}/edit`)}
                                >
                                    EDIT
                                </button>
                                <button
                                    className="px-6 py-2 font-semibold text-center text-white bg-red-600 rounded-xl"
                                    onClick={() => setOpen(false)}
                                >
                                    CLOSE
                                </button>
                            </div>
                        </div>
                    </CustomModal>
                </div>
                <div className="mx-2">

                </div>
            </section>
        </>
    );
};

export default TransactionTable;
