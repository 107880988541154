import {configureStore} from "@reduxjs/toolkit";
import OrderReducer from "./features/ordersSlice"; 
import TransactionReducer from "./features/transactionSlice";
import StocksReducer from "./features/stockSlice";
import DashboardReducer from "./features/dashboardSlice";
import UserReducer from "./features/userSlice";
import MessageReducer from "./features/messageSlice";

export const store = configureStore({
    reducer: {
        orders: OrderReducer,
        transactions: TransactionReducer,
        stocks: StocksReducer,
        dashboard: DashboardReducer,
        user: UserReducer,
        messages: MessageReducer
    },
}
);