import { Tooltip, Zoom } from '@mui/material';
import React from 'react';
import {IoArrowBack} from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';

const GoBack = ({to}) => {
    const navigate = useNavigate();
    const handleGoback = () => {
        if(to === "-1"){
            navigate(Number(to));
        }else{
            navigate(to);
        }
    }

  return (
    <div className='mr-3 w-max'>
        <Tooltip title={<h3 className="text-sm">Go back</h3>} placement="bottom" arrow TransitionComponent={Zoom}>
            <IconButton onClick={handleGoback}>
                <IoArrowBack className="text-2xl cursor-pointer" />
            </IconButton>
        </Tooltip>
    </div>
  )
}

export default GoBack;