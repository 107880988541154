export const validateTransaction = body => {
    const requiredFields = ["consumerId","retailerId","paymentStatus","totalAmount"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateProfile = body => {
    const requiredFields = [
        'ownerName','mobile','storeName','storeType',
        'storeCategory','location','payeeName',
        'bankAccountNumber','bankName','bankBranch','bankIFSC'
    ];
    return _checkRequiredFields(body,requiredFields);
};

export const validateMessage = body => {
    const requiredFields = ["retailerId","text","sender"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateOrder = body => {
    const requiredFields = ["retailerId","storeName","items","paymentStatus"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateStock = body => {
    const requiredFields = ["retailerId","items"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateConsumerReminder = body => {
    const requiredFields = ["storeName","dueAmountRaw","consumerId"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateFilter = body => {
    const requiredFields = ["month","year"];
    return _checkRequiredFields(body,requiredFields);
};

export const validateMobile = mobile => {
    if(isEmpty(mobile) || mobile.length !== 10 || isNaN(mobile/10)) return false;
    return true;
};

export const validateSignUp = (body,section) => {
    let requiredFields = [];
    switch(section){
        case 1:
            requiredFields = ['ownerName','mobile','gender'];
            break;
        case 2:
            requiredFields = ['storeName','location','storeType',"storeCategory"];
            break;
        case 3:
            requiredFields = ['payeeName','bankAccountNumber','bankName','bankBranch','bankIFSC'];
            break;
        default:
            break;
    }
    return _checkRequiredFields(body,requiredFields);
};

const _checkRequiredFields = (body,requiredFields) => {
    for(let field of requiredFields){
        if(isEmpty(body[field])){
            return false;
        }
    }
    return true;
};

export const sanitizeValue = value => isEmpty(value) ? 0 : value;

export const isEmpty = value => {
    return value === null || value === undefined || value.toString().trim().length === 0;
};

export const checkFloat = value => {
    return isNaN(parseFloat(value)) ? false : true;
};