import { Box, Fade, Modal } from '@mui/material';
import React from 'react';
import Backdrop from '@mui/material/Backdrop';
import { AiOutlineClose } from 'react-icons/ai';


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 420,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 0,
  };

const CustomModal = ({open,setOpen,children}) => {
  return (
    <div>
      
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
        
      }}
    >
       <Fade in={open}>

      <Box sx={style} className="rounded-xl min-h-[50%] max-h-screen">
        
        <div className='relative rounded-3xl'>
         
          {children}
        </div>
      </Box>
       </Fade>
    </Modal>
  </div>
  );
}

export default CustomModal;
