import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { getProfile, updateProfile } from "../../services/api";

const initialState = {
    isLoggedIn: false,
    data: {
        message: null,
        retailerId: null,
        storeName: null,
        storeType: null,
        token: null
    },
    profile: null
};

export const fetchProfile = createAsyncThunk(
    "user/fetchProfile",
    async (retailerId, { rejectWithValue }) => {
		try {
			const response = await getProfile(retailerId);
			return response.success ? response.result : null;
		}
		catch(error){
			console.warn(error);
			return rejectWithValue(error.response?.data);
		}
    }
);

export const changeProfile = createAsyncThunk(
    "user/changeProfile",
    async (body, { rejectWithValue, dispatch, getState }) => {
		try {
			const response = await updateProfile(body, getState().user.data.retailerId);
			if(response.success) dispatch(fetchProfile(getState().user.data.retailerId))
		}
		catch(error){
			console.warn(error);
			return rejectWithValue(error.response?.data);
		}
    }
);

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        update: (state,action) => {
            state.isLoggedIn = true;
            state.data = action.payload
        }
    },
    extraReducers: {
        [fetchProfile.fulfilled]: (state,action) => (
            { ...state, profile: action.payload}
        ),
    },
});

export const user = (state) => state.user;
export const userActions = userSlice.actions;
export default userSlice.reducer;
