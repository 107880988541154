import { Tooltip, Zoom } from '@mui/material'
import React from 'react'


export default function CustomButton({ disabled, children }) {
    return (
        <div>
            {
                disabled ?
                    <>
                        {children}
                    </>
                    :
                    <Tooltip
                        title={<h3 className="text-sm">Send Reminder</h3>}
                        placement="left"
                        arrow
                        TransitionComponent={Zoom}
                        TransitionProps={{ timeout: 600 }}
                    >
                        {children}
                    </Tooltip>
            }
        </div>
    )
}
