import React from 'react';
import TransactionTable from "../components/TransactionData/TransactionTable"
const TransactionPage = ({ user }) => {
  return (
    <div>
      <TransactionTable user={user} />
    </div>
  );
}

export default TransactionPage;
