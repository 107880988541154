import React from "react";
import Lottie from "lottie-react";
import notFound from "./notFound.json";

const style = {
    height: 400,
    

  };
const PageNotFound = () => {
  return (
    <div className="flex items-center justify-center w-full h-screen">
      <Lottie animationData={notFound} loop={true}
      style={style}
      />
    </div>
  );
};

export default PageNotFound;
