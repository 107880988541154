import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import Header from "../Common/Header";
import {showNotification} from "../../services/helper"
import { uploadImage } from "../../services/api";
import { useDispatch, useSelector } from "react-redux";
import { changeProfile, fetchProfile } from "../../redux/features/userSlice";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProfileImage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [files, setFiles] = useState([]);
  const [editData,setEditData] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const { profile,data } = useSelector(state => state.user);

  useEffect(() => {
    if(!profile) dispatch(fetchProfile(data.retailerId));
    else if(profile) _prepareEditData(profile); 
},[profile]);

const _prepareEditData = profileData => {
    const fields = [
        "ownerName","gender","email","panNumber","bonusScheme","mobile",
        "storeName","storeType","storeCategory","gstNumber","location","district","state",
        "payeeName","bankAccountNumber","bankName","bankBranch","bankIFSC",
        "images"
    ]
    const obj = {};
    fields.forEach(field => obj[field] = profileData[field]);
    setEditData(obj);
    };


  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      "image/*": [],
    },
    noKeyboard: true,
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      );
      setIsUploaded(false);
    },
  });
  const thumbs = files.map(file => (
    <div>
        <div className='thumb' key={file.name}>
            <div className='thumbInner'>
                <img
                    src={file.preview}
                    alt=""
                    className='block w-full h-auto'
                    // Revoke data uri after image is loaded
                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                />
            </div>
        </div>
        <button className='m-1 text-base text-red-600' onClick={() => setFiles([])}>Remove</button>
        <p className='text-xs'>{file.path} - {(file.size/1024).toFixed(2)}kb</p>
    </div>
    
));

const imageHandler = async () => {
    try{
        if(files.length > 0){
            setIsUploading(true);
            const formData = new FormData();
            formData.append("file",files[0]);
            const response = await uploadImage(formData);
            if(response.status === 200 && response.data.success){
                const newFormData = { ...editData };
                newFormData.images = [...newFormData.images , response.data.result.Location];
                setEditData(newFormData);
                showNotification("success", "File Uploaded");
                setIsUploaded(true);
                dispatch(changeProfile(newFormData));
                navigate(-1);
            }
        }else{
            showNotification("error", "No file to upload");
        }
    }
    catch(err){
        console.warn(err);
    }finally{
        setIsUploading(false);
        setFiles([]);
    }
};

  return (
    <section>
      <div className="p-5">
        <Header title="Upload Images" showBackButton={true} to="-1" />
      </div>
      <div
        className="w-4/5 w-max-[400px] mx-auto flex flex-col items-center justify-center p-10 text-xl font-semibold text-black transition-all duration-200 ease-in-out bg-white border-2 border-blue-500 border-dashed outline-none rounded-xl"
      >
        <div {...getRootProps({ className: "cursor-pointer" })}>
          <input {...getInputProps()} />
          <p>
            <span className="text-blue-600 underline">Click here</span> to select file</p>
        </div>
        <aside className="thumbsContainer">{thumbs}</aside>
        {files.length ? (
          <button
            disabled={isUploading}
            className="px-2 py-1 mt-2 text-sm text-white bg-blue-600 rounded-lg drop-shadow-md w-36"
            onClick={imageHandler}
          >
            {isUploading ? "Uploading" : "Upload"}
          </button>
        ) : (
          ""
        )}
        {isUploaded ? (
          <p className="text-lg tracking-wide">
            <span className="text-green-600">Uploaded</span>
          </p>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};

export default ProfileImage;
