import React, { useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { months, numberOfDays, showNotification, years } from "../../services/helper";
import moment from "moment";
import Header from "../Common/Header";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { getPayout } from "../../services/api";
import Loader from "../Common/Loader";
import { sanitizeValue } from "../../services/validation";


const iconButtonStyle = `flex items-center justify-center 
text-md text-white bg-blue-700 px-4 py-2 rounded-xl
hover:text-blue-100 hover:bg-sky-400 duration-500`;

const selectStyle = "px-8 py-1 text-sm font-semibold border border-gray-400 rounded-xl";
const optionStyle = "text-base font-medium";
const tableHeaderStyle = {
	color: "white",
	fontSize: "14px"
}
const columns = [
	{ id: 1, header: "Card Number" },
	{ id: 2, header: "Name" },
	{ id: 3, header: "Date" },
	{ id: 4, header: "Amount" },
	{ id: 5, header: "Card" },
	{ id: 6, header: "Status" },
];

const initialState = {
	year: "",
	month: "",
};

const PayoutTable = ({ user }) => {
	const [payout, setPayout] = useState(null);
	const [form, setForm] = useState({ ...initialState });
	const [isLoading, setIsLoading] = useState(false);
	const { retailerId } = user;

	const handleChange = (type, data) => {
		const obj = { ...form };
		obj[type] = data;
		setForm(obj);
	};

	const handleSearch = async () => {
		try {
			setIsLoading(true);
			const startDate = `${form.year}-${parseInt(form.month) + 1}-01`;
			const endDate = `${form.year}-${parseInt(form.month) + 1}-${numberOfDays[form.month]}`;
			const response = await getPayout({ startDate, endDate, retailerId });
			if (response.success) setPayout(response.result);
			else showNotification("warning", "Unable to get payouts");
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to get payouts");
		}
		finally {
			setIsLoading(false);
		}
	};

	const arr = payout?.list?.map((item) => {
		return (
			<TableRow key={item.id}>
				<TableCell align="left" sx={{ fontSize: "12px" }} className="uppercase">{item.consumerCard}</TableCell>
				<TableCell align="left" sx={{ fontSize: "12px" }} >{item.consumerName}</TableCell>
				<TableCell align="left" sx={{ fontSize: "12px" }}>{moment(item.createdDate).format("llll")}</TableCell>
				<TableCell align="left" sx={{ fontSize: "12px" }}>₹{item.totalAmount}/-</TableCell>
				<TableCell align="left" sx={{ fontSize: "12px" }} className="uppercase">{item.cardType}</TableCell>
				<TableCell align="left" sx={{ fontSize: "12px" }}>
					<div
						className={`uppercase text-left font-semibold
						${item.paymentStatus === "paid" && "text-green-500"}
						${(item.paymentStatus === "partial", "pending" && "text-red-600")}`}
					>
						{item.paymentStatus}
					</div>
				</TableCell>
			</TableRow>
		);
	});

	return (
		<>
			<section className="max-w-full p-5 mx-auto">
				<Header title={`PayOut (${sanitizeValue(payout?.count)})`} showLogout={true} showBackButton={true} to="-1" />
				<div className="px-4 py-4 -mx-4 overflow-x-auto sm:-mx-8 sm:px-8">
					<div className="flex items-center justify-between py-4">
						<div className="flex pl-10 space-x-10">
							<div className="flex pl-10 space-x-10">
								<select
									onChange={(e) => handleChange("month", e.target.value)}
									className={selectStyle}
									value={form.month}
								>
									<option value="" className={optionStyle}>Select Month</option>
									{months.map((item, index) => (
										<option key={item} value={index} className="text-base font-medium">{item}</option>
									))}
								</select>
								<select
									onChange={(e) => handleChange("year", e.target.value)}
									className={selectStyle}
									value={form.year}
								>
									<option value="" className={optionStyle}>Select Year</option>
									{years.map(item => (
										<option key={item} value={item} className="text-base font-medium">{item}</option>
									))}
								</select>
								<button className={iconButtonStyle} onClick={handleSearch}>Search</button>
							</div>

						</div>
					</div>
					<section className="min-w-[95%] max-w-[90%] mx-auto">
						{isLoading && <Loader />}
						{payout?.count &&
							<>
								<div className="flex py-4 space-x-5">
									<div className="px-6 py-4  border border-gray-400 border-solid rounded bg-[#e7eaf6]">
										<p className="text-base font-medium">Total Amount: ₹ {sanitizeValue(payout?.totalAmount)}</p>
									</div>
									<div className="px-6 py-4 border border-gray-400 border-solid rounded bg-[#e0ffcd] ">
										<p className="text-base font-medium">Total Bonus: ₹ {sanitizeValue(payout?.totalBonus)}</p>
									</div>
								</div>
								<TableContainer component={Paper}>
									<Table sx={{ minWidth: 650 }} size="small">
										<TableHead>
											<TableRow sx={{
												backgroundColor: "#79c2d0",
											}}>
												{columns.map((head) => (
													<TableCell
														sx={tableHeaderStyle}
														align="left"
														key={head.id}
														className="uppercase"
													>
														{head.header}
													</TableCell>
												))}
											</TableRow>
										</TableHead>
										<TableBody>{arr}</TableBody>
									</Table>
									{/* <div className="flex items-center justify-center">
										<TablePagination align="left" component="div" count={100} />
									</div> */}
								</TableContainer>
							</>
						}
					</section>
				</div>
			</section>

		</>
	);
}

export default PayoutTable;
