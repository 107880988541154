import React from 'react';


const DashboardCard  = ({title, value}) => {
  return (
    <div className='pr-dashboard-grid-item'>
      <p className='font-semibold text-gray-600 text-md'>{title}</p>
      <p className='font-normal text-violet-600'>{value}</p>
	</div>
  )
}

export default DashboardCard
