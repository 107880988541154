import React, { useState,useEffect } from "react";
import ChatBox from "react-chat-plugin";
import { useSelector,useDispatch } from "react-redux";
import { messagesGet,messageAdd,selectAllMessages } from "../../redux/features/messageSlice";
import Loader from "./Loader";
import { validateMessage } from "../../services/validation";
import { showNotification } from "../../services/helper";
import Header from "./Header";
import logo from "../../assets/images/logo.png"
import support from "../../assets/images/support.png"

const sender = "retailer";
const Support = ({ user }) => {
    const { retailerId } = user;
    const dispatch = useDispatch();
    const messages = useSelector(selectAllMessages);
    const [messageList,setMessageList] = useState([]);

    useEffect(() => {
        if(!messages.hasData) dispatch(messagesGet(retailerId));
        else _prepareMessages(messages?.data?.contents || []);
    },[messages]);

    const _prepareMessages = (arr) => {
        const list = [];
        for(let item of arr){
            list.push({
                author: { id: item.sender === "admin" ? 2 : 1, username: item.sender === "admin" ? "HYB" : "You" , avatarUrl: item.sender === "admin" ?  logo : support},
               
                text: item.text,
                type: "text",
                timestamp: new Date(item.createdDate),
                hasError: false
            });
        }
        setMessageList(list);
    };
 
    const sendHandler = text => {
        const body = { retailerId,text,sender };
        if(validateMessage(body)){
            dispatch(messageAdd(body));
        }
        else showNotification("error","Text cannot be empty");
    };

    if(!messages.hasData) return <Loader />;
    return (
        <section>
        <div className="px-4 py-2">
        <Header title="Support Chat" />
        </div>
            <div className="flex justify-center">
            <ChatBox
                messages={messageList}
                userId={1}
                onSendMessage={sendHandler}
                height={"90vh"}
            />
            </div>        
        </section>
    );
};

export default Support;
