import { toast } from "react-toastify";

export const showNotification = (type="success",message,time=1500) => {
    toast[type](message,{ autoClose:time });
};

export const months = ["Jan","Feb","March","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
export const numberOfDays = [31,28,31,30,31,30,31,31,30,31,30,31];
export const years = [2021,2022,2023,2024,2025];
export const getStartDate = (month,year) => `${year}-${parseInt(month) + 1}-01`;
export const getEndDate = (month,year) => `${year}-${parseInt(month) + 1}-${numberOfDays[month]}`;

export const getBonusLimit = scheme => {
    switch(scheme){
        case "10K & above":
            return 10000;
        case "15K & above":
            return 15000;
        case "20K & above":
            return 20000;
        case "30K & above":
            return 30000;
        case "50K & above":
            return 50000;
        case "60K & above":
            return 60000;
        case "100K & above":
            return 100000;
        default:
            return 10000;
    }
};

export const debouncer = function(func,wait){
    let timeout;
    return function(){
        const context = this, args = arguments;
        const later = function(){
            timeout = null;
            func.apply(context,args);
        }
        clearTimeout(timeout);
        timeout = setTimeout(later,wait);
    };
};

export const setItem = (key,value) => localStorage.setItem(key,JSON.stringify(value));

export const getItem = key => {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
}