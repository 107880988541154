import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { stocksGet,selectAllStocks,stocksUpdate } from "../../redux/features/stockSlice";
import { Stack } from "@mui/material/";
import { Pagination } from "@mui/material/";
import Header from "../Common/Header";
import Loader from "../Common/Loader";
import { sanitizeValue,validateStock } from "../../services/validation";
import { showNotification } from "../../services/helper";

const Inventory = ({ user }) => {
    const limit = 24;
    const [currentPage, setCurrentPage] = useState(0);
    const stocks = useSelector(selectAllStocks);
    const dispatch = useDispatch();
    const { retailerId } = user;
    const [form,setForm] = useState(null);

    useEffect(() => {
        if(!stocks.hasData) dispatch(stocksGet(retailerId));
        else setForm({ ...stocks?.data?.list[0] });
    },[stocks]);

    const handleChangePage = (e,n) => setCurrentPage(n - 1);
    const save = () => {
        if(validateStock(form)){
            dispatch(stocksUpdate(form));
        }
        else showNotification("warning","Missind Required Fields");
    };

    const handleChange = (index,value) => {
        const obj = { ...form };
        const items = [...obj.items];
        const item = { ...items[currentPage * limit + index] };
        item.count = sanitizeValue(value);
        items[currentPage * limit + index] = item;
        obj.items = items;
        setForm(obj);
    };
    
    if(!form) return <Loader />;
    
    const arr = form?.items
    ?.slice(limit * currentPage, limit * currentPage + limit)
    .map((item,index) => (
        <div key={item.id} className="flex items-center justify-between p-4 bg-white shadow-xl rounded-xl">
            <div>
                <p className="font-sans font-semibold text-medium uppercase">{item?.name}</p>
                <div className="mt-5 flex items-center">
                    <input
                        value={item?.count || 0}
                        className="px-4 py-2 border-gray-500 rounded shadow-md border-1"
                        type="number"
                        min={0}
                        onChange={e => handleChange(index,e.target.value)}
                    />
                    <p className="text-sm mx-5 font-medium text-gray-600">{item?.unit}</p>
                </div>
            </div>
        </div>
    ));

    return (
        <>
            <section className="p-5">
                <Header title="Edit Inventory" showBackButton={true} to="-1" />
                <div className="flex justify-between">
                    <div className="flex flex-col">
                        <p className="mr-2 font-mono text-gray-400">Total Items</p>
                        <p className="px-1 font-mono text-2xl font-semibold">{stocks?.data?.list[0]?.items?.length}</p>
                        <button className="p-2 mt-5 text-sm font-medium text-white bg-blue-600 rounded" type="button" onClick={save}>Save</button>
                    </div>
                </div>
            </section>
            <section className="grid grid-cols-3 gap-4 p-4 ">{arr}</section>
            <div className="py-5">
                <Stack spacing={2} className="flex items-center justify-center">
                <Pagination
                    count={Math.ceil(stocks?.data?.list[0]?.items?.length / limit)}
                    variant="outlined"
                    shape="rounded"
                    showFirstButton
                    showLastButton
                    color="primary"
                    page={currentPage + 1}
                    onChange={(e, n) => handleChangePage(e, n)}
                />
                </Stack>
            </div>
        </>
    );
};

export default Inventory;
