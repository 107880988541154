import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
	AiFillCar,
	AiOutlineExclamationCircle,
	AiOutlineGift,
	AiOutlineMobile,
} from "react-icons/ai";
import {
	BsCalendarEvent,
	BsCart3,
	BsCashStack,
	BsCheck2Circle,
	BsFileEarmarkExcel,
	BsFillPersonFill,
} from "react-icons/bs";
import { HiShoppingCart } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ordersGet, selectAllOrders } from "../../redux/features/ordersSlice";
import CustomModal from "../CustomModal/CustomModal";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { sanitizeValue, validateFilter } from "../../services/validation";
import Header from "../Common/Header";
import { Pagination, Stack } from "@mui/material";
import { getEndDate, getStartDate, months, numberOfDays, showNotification, years } from "../../services/helper";
const tableRowStyle = { fontSize: "14px" };
const tableHeaderStyle = {
	color: "white",
	fontSize: "16px"
}
const iconButtonStyle = `flex items-center justify-center 
text-md text-white bg-blue-700 px-4 py-2 rounded-xl
hover:text-blue-100 hover:bg-sky-400 duration-500`;
const optionStyle = "text-base font-medium";
const selectStyle = "px-8 py-1 text-sm font-semibold border border-gray-400 rounded-xl";
const limit = 20;
const offset = 0;
const initialState = {
	year: "",
	month: "",
};

const Orders = ({ user }) => {
	const { retailerId } = user;
	const [currentPage, setCurrentPage] = useState(0);
	const tableRef = useRef(null);
	const orders = useSelector(selectAllOrders);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [view, setView] = useState(1);
	const [open, setOpen] = useState(false);
	const [activeOrder, setActiveOrder] = useState(null);
	const [form, setForm] = useState({ ...initialState });
	const [isLoading, setIsLoading] = useState(false);
	const [searchData, setSearchData] = useState({});


	useEffect(() => {
		if (!orders.hasData) {
			dispatch(ordersGet({ retailerId, offset, limit }));
		}
	}, []);

	const handleOpen = (order) => {
		setActiveOrder(order);
		setOpen(true);
	};

	const handleChangePage = (e, n) => {
		const params = { retailerId, offset: (n - 1) * limit, limit };
		dispatch(ordersGet(params));
		setCurrentPage(n - 1);
	};

	const handleChange = (type, data) => {
		const obj = { ...form };
		obj[type] = data;
		setForm(obj);
	};
	const handleSearch = async () => {
		try {
			if (validateFilter(form)) {
				setIsLoading(true);
				const startDate = getStartDate(form.month, form.year);
				const endDate = getEndDate(form.month, form.year);
				dispatch(ordersGet({ retailerId, offset, limit, startDate, endDate }));
				setCurrentPage(0);
			}
			else showNotification("warning", "Filters cannot be empty");
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to get payouts");
		}
		finally {
			setIsLoading(false);
		}
	};



	const arr = orders?.data?.list?.map((item) => {
		return (
			<TableRow key={item.id}>
				<TableCell sx={tableRowStyle} className="uppercase">{item.id}</TableCell>
				<TableCell sx={tableRowStyle}>
					{moment(item.createdDate).format("DD/MM/YYYY hh:mm:ss a")}
				</TableCell>
				<TableCell sx={tableRowStyle}>
					₹ {sanitizeValue(item.totalAmount).toFixed(2)}/-
				</TableCell>
				<TableCell sx={tableRowStyle}>
					₹ {item.paymentStatus === "partial" ? (sanitizeValue(item.totalAmount) - sanitizeValue(item.paidAmount)).toFixed(2) : 0} /-
				</TableCell>
				<TableCell sx={tableRowStyle}>
					<div
						className={`uppercase text-left  
							${item.status === "active" && "text-green-600"} ${item.status === "completed" && "text-blue-600"
							} ${item.status === "cancelled" && "text-red-600"}`
						}
					>
						{item.status}
					</div>
				</TableCell>
				<TableCell sx={tableRowStyle}>
					<div
						className={`uppercase text-left
							${item.paymentStatus === "paid" && "text-green-600"}
							${item.paymentStatus === "pending" && "text-red-500"}
							${item.paymentStatus === "partial" && "text-indigo-500"}`
						}
					>
						{item.paymentStatus}
					</div>
				</TableCell>
				<TableCell className="flex items-left justify-left ">
					<button
						onClick={() => handleOpen(item)}
						className="px-2 py-1 text-xs text-left text-white bg-blue-500 rounded-lg hover:bg-blue-600"
					>
						VIEW
					</button>
				</TableCell>
			</TableRow>
		);
	});
	const view1 = () => {
		return (
			<section className="flex flex-col pl-8 space-y-3">
				<div className="flex items-center mt-4 space-x-5 ">
					<AiOutlineExclamationCircle className="text-xl" />
					<div>
						<label className="text-xs text-gray-500">Order ID</label>
						<p className="font-semibold text-md">{activeOrder?.id}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<BsCalendarEvent className="text-xl" />
					<div>
						<label className="text-xs text-gray-500">Order Date</label>
						<p className="font-semibold text-md">{moment(activeOrder?.createdDate).format("llll")}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<BsCheck2Circle className="text-xl text-green-600" />
					<div>
						<label className="text-xs text-gray-500">Payment Status</label>
						<p className="font-semibold uppercase text-md">{activeOrder?.paymentStatus}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<BsCashStack className="text-xl" />
					<div>
						<label className="text-xs text-gray-500">Total Amount</label>
						<p className="font-semibold text-md">{activeOrder?.totalAmount}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<BsCart3 className="text-xl" />
					<div>
						<label className="text-xs text-gray-500">Total Item</label>
						<p className="font-semibold text-md">{activeOrder?.items.length}</p>
					</div>
				</div>
			</section>
		);
	};
	const view2 = () => {
		return (
			<section>
				<div className="flex flex-col pl-8 mt-4 space-y-3">
					{activeOrder.items.map((item) => (
						<div className="flex items-center mb-3 space-x-4" key={item.id}>
							<HiShoppingCart className="text-xl" />
							<div>
								<p className="text-xs font-medium">{item.name}</p>
								<div className="flex items-center space-x-2 text-sm font-medium">
									<p className="font-semibold text-md">₹ {item.mrp} x {item.itemCount} = ₹ {item.mrp * item.itemCount}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</section>
		);
	};
	const view3 = () => {
		return (
			<section className="flex flex-col pl-8 space-y-5">
				<div className="flex items-center mt-4 space-x-5">
					<BsFillPersonFill className="text-xl text-gray-500" />
					<div>
						<label className="text-sm font-normal text-gray-500">Contact Name</label>
						<p className="text-sm font-medium">{activeOrder?.shippingInfo?.contactName || "NA"}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<AiOutlineMobile className="text-xl text-gray-500" />
					<div>
						<label className="text-sm font-normal text-gray-500">Mobile</label>
						<p className="text-sm font-medium">{activeOrder?.shippingInfo?.contactNumber || "NA"}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<AiFillCar className="text-xl text-gray-500" />
					<div>
						<label className="text-sm font-normal text-gray-500">Vehicle Number</label>
						<p className="text-sm font-medium uppercase">{activeOrder?.shippingInfo?.vehicleNumber || "NA"}</p>
					</div>
				</div>
				<div className="flex items-center space-x-5">
					<AiOutlineGift className="text-xl text-gray-500" />
					<div>
						<label className="text-base text-gray-500">Packaging</label>
						<p className="text-sm font-medium uppercase">Bags: {activeOrder?.shippingInfo?.bags || "NA"}</p>
						<p className="text-sm font-medium uppercase">Loose: {activeOrder?.shippingInfo?.loose || "NA"}</p>
						<p className="text-sm font-medium uppercase">Cartons: {activeOrder?.shippingInfo?.cartons || "NA"}</p>
					</div>
				</div>
			</section>
		);
	};
	return (
		<>
			<div className="p-5 bg-white">
				<Header
					title={`Orders : ${sanitizeValue(orders?.data?.count)}`}
					showReload={true}
					showLogout={true}
					func={() => dispatch(ordersGet({ retailerId, offset, limit }))} />

			</div>
			<section className="flex justify-between bg-white">
				<div className="flex justify-between my-8 ">
					<div className="flex px-4 space-x-3">
						<DownloadTableExcel
							filename="Orders"
							sheet="OrderTable"
							currentTableRef={tableRef.current}
						>
							<button className="flex items-center justify-center px-5 py-2 text-xs text-white bg-green-600 focus:bg-green-500 hover:bg-green-400 rounded-xl">
								<BsFileEarmarkExcel /> Download Excel
							</button>
						</DownloadTableExcel>
					</div>
					<div className="flex pl-10 space-x-10">
						<select
							onChange={(e) => handleChange("month", e.target.value)}
							className={selectStyle}
							value={form.month}
						>
							<option value="" className={optionStyle}>Select Month</option>
							{months.map((item, index) => (
								<option key={item} value={index} className="text-base font-medium">{item}</option>
							))}
						</select>
						<select
							onChange={(e) => handleChange("year", e.target.value)}
							className={selectStyle}
							value={form.year}
						>
							<option value="" className={optionStyle}>Select Year</option>
							{years.map(item => (
								<option key={item} value={item} className="text-base font-medium">{item}</option>
							))}
						</select>
						<button className={iconButtonStyle} onClick={handleSearch}>Search</button>
					</div>

				</div>

				<div className="flex items-center px-6 gap-x-5">
					<button
						className="px-5 py-2 text-sm font-semibold text-white bg-blue-700 rounded-xl"
						onClick={() => navigate("/admin/orders/add")}
					>
						Add Order
					</button>
				</div>
			</section>



			<section className="max-w-[700px] min-w-[98%] mx-auto">
				<TableContainer component={Paper}>
					<Table sx={{ minWidth: 650 }} size="small">
						<TableHead>
							<TableRow sx={{
								backgroundColor: "#8f71ff",
							}}>
								<TableCell align="left" sx={tableHeaderStyle}>ID</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}>Date & Time</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}>Amount</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}>Due</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}>Order Status</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}> Payment Status</TableCell>
								<TableCell align="left" sx={tableHeaderStyle}> Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{orders?.data?.count === 0 ? <TableCell sx={{
								padding: 5,
								fontSize: "15px"
							}}>No Data Available</TableCell> : arr}
						</TableBody>
					</Table>

				</TableContainer>
				<Stack spacing={2} className="flex items-center my-5 ">
					<Pagination
						count={Math.ceil(sanitizeValue(orders?.data?.count) / limit)}
						variant="outlined"
						shape="rounded"
						showFirstButton
						showLastButton
						color="primary"
						page={currentPage + 1}
						onChange={(e, n) => handleChangePage(e, n)}
					/>
				</Stack>
			</section>
			<CustomModal open={open} setOpen={setOpen}>
				<>
					<div className="flex flex-col px-4 pt-5  pl-4 space-y-2 min-h-[400px] max-h-[650px]">
						<div className="flex justify-between gap-x-4 ">
							<button
								onClick={() => setView(1)}
								className={`w-[33%] py-2 ${view === 1 ? "bg-blue-700 text-white" : ""} rounded-xl`}
							>
								ORDER INFO
							</button>
							<button
								onClick={() => setView(2)}
								className={`w-[33%] py-2 ${view === 2 ? "bg-blue-700 text-white" : ""} rounded-xl`}
							>
								ITEMS
							</button>
							<button
								onClick={() => setView(3)}
								className={`w-[33%] py-2 ${view === 3 ? "bg-blue-700 text-white" : ""} rounded-xl`}
							>
								SHIPPING
							</button>
						</div>
						{view === 1 && view1()}
						{view === 2 && view2()}
						{view === 3 && view3()}
					</div>
					<div className="flex justify-center py-2">
						<button
							className="px-4 py-2 font-semibold text-center text-white bg-red-600 rounded-xl"
							onClick={() => setOpen(false)}
						>
							CLOSE
						</button>
					</div>
				</>
			</CustomModal>

		</>
	);
};

export default Orders;
