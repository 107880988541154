import React from "react";
import ChatBox from "../Common/ChatBox";

const ChatPopUp = ({ ShowChatPopUp }) => {
  return (
    <div className="">
      {ShowChatPopUp && <ChatBox />}
    </div>
  );
};

export default ChatPopUp;
