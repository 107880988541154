import axios from "axios";
// import { logoutUser } from "../redux/actions/userActions";

axios.defaults.baseURL = process.env.REACT_APP_ENV === 'dev' ? "http://192.168.29.150:8000/" : "https://api.healyourbiz.in";
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';

export const setAuthHeader = token => {
    axios.defaults.headers["Authorization"] = token;
    return true;
};

export const clearAuthHeader = () => {
    axios.defaults.headers["Authorization"] = null;
    return true;
}

// AUTHENTICATION APIS 
export const logout = body => _callApi(`/api/v2/auth/logout`, "POST", body);
export const getOTP = body => _callApi(`/api/v2/auth/retailers/otp`, "POST", body);
export const verifyOTP = body => _callApi(`/api/v2/auth/otp/validate`, "POST", body);

//DASHBOARD APIs
export const getDashboardCounts = retailerId => _callApi(`/api/dashboard/retailer/${retailerId}/counts`, "GET");

// PROFILE APIs
export const getProfile = id => _callApi(`/api/v2/retailers/${id}`, "GET");
export const updateProfile = (body, retailerId) => _callApi(`/api/retailers/id/${retailerId}`, "PUT", body);

// ORDER APIs
export const addOrder = body => _callApi(`/api/v2/orders`, "POST", body);
export const getAllOrders = (retailerId, offset = 0, limit = 20, startDate, endDate) => _callApi(`/api/v2/orders?retailerId=${retailerId}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`, "GET");
export const getOrder = id => _callApi(`/api/v2/orders/${id}`, "GET");

// TRANSACTION APIs
export const addTransaction = body => _callApi(`/api/v2/transactions`, "POST", body);
export const getAllTransactions = (retailerId, offset = 0, limit = 20, startDate, endDate) => _callApi(`/api/v2/transactions?retailerId=${retailerId}&offset=${offset}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`, "GET");
export const getReminderList = (retailerId, offset = 0, limit = 20) => _callApi(`/api/v2/transactions/reminders?retailerId=${retailerId}&offset=${offset}&limit=${limit}`, "GET");
export const getTransaction = id => _callApi(`/api/v2/transactions/${id}`, "GET");
export const updateTransaction = (id, body) => _callApi(`/api/v2/transactions/${id}`, "PUT", body);
export const deleteTransaction = id => _callApi(`/api/v2/transactions/${id}`, "DELETE");
export const getPayout = ({ startDate, endDate, retailerId }) => _callApi(`/api/v2/transactions/payout?retailerId=${retailerId}&startDate=${startDate}&endDate=${endDate}`, "GET");

// CONSUMER APIs
export const reminder = body => _callApi(`/api/v2/consumers/reminder`, "POST", body);
export const verifyConsumerCard = cardNumber => _callApi(`/api/v2/consumers/verify?cardNumber=${cardNumber}`, "GET");

// PRODUCT APIs
export const searchProducts = keyword => _callApi(`/api/v2/items/search?field=name&keyword=${keyword}`, "GET");

// REQUEST APIs
export const requestItem = request => _callApi(`/api/v2/requests`,'POST',request);

// export const getAllProducts = () => _callApi(`/api/items?offset=0&limit=5000`,"GET");
// export const searchProducts = keyword => _callApi(`/api/items/search?entity=name&keyword=${keyword}`,"GET");
// export const getProduct = id => _callApi(`/api/items/id/${id}`,"GET");

// STOCK APIs
export const getStocks = retailerId => _callApi(`/api/v2/stocks?retailerId=${retailerId}`, "GET");
export const addStock = form => _callApi(`/api/v2/stocks`, "POST", form);
export const updateStock = (id, form) => _callApi(`/api/v2/stocks/${id}`, "PUT", form);

// SUPPORT APIs
export const getMessages = (retailerId, offset = 0, limit = 20) => _callApi(`/api/v2/support?retailerId=${retailerId}&offset=${offset}&limit=${limit}`, "GET");
export const sendMessage = form => _callApi(`/api/v2/support`, "POST", form);

//Image API
export const uploadImage = body => {
    const headers = {
        "Content-Type": "application/json",
        "Authorization": "IVHEOSKOIVCVESKOI5AEGT2EIVMFA==="
    };
    return axios.post("https://api.sindam.in/common/upload?bucket=healyourbiz", body, { headers });
};

const _callApi = async (url, method = 'GET', body = {}) => {
    try {
        const response = await axios[method?.toLowerCase()](url, body);
        const { status, data } = response;
        if (status === 401) {
            return { success: false };
        }
        else if (status === 200 || status === 201) {
            return data;
        }
        else {
            return { success: false };
        }
    }
    catch (err) {
        console.warn(err);
        return { success: false };
    }
};