import { TextField } from "@mui/material";
import React, { useEffect,useState } from "react";
import { BsCashStack,BsCheck2Circle } from "react-icons/bs";
import { useNavigate,useParams } from "react-router-dom";
import { getTransaction } from "../../../services/api";
import Loader from "../../Common/Loader";
import { useDispatch } from "react-redux";
import { transactionUpdate } from "../../../redux/features/transactionSlice";
import { validateTransaction,isEmpty,checkFloat } from "../../../services/validation";
import { showNotification } from "../../../services/helper";
import Header from "../../Common/Header";

const buttonStyle = "px-8  text-base font-medium bg-blue-600 text-white py-2 rounded";
const options = [
	{ id: 1, value: "paid", label: "Paid" },
	{ id: 2, value: "pending", label: "Pending" },
	{ id: 3, value: "partial", label: "Partial" }
]
const TransactionUpdate = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { id } = useParams();
	const [updateForm,setUpdateForm] = useState(null);

	useEffect(() => {
		if(id) _fetchTransaction();	
	},[id]);

	const _fetchTransaction = async () => {
		try{
			const response = await getTransaction(id);
			if(response.success && response.result) setUpdateForm(response.result);
			else setUpdateForm({});
		}
		catch(err){
			console.warn(err);
			showNotification("error","Unable to fetch transaction");
		}
	};

	const handleChange = (field,e) => {
		const obj = { ...updateForm };
		obj[field] = e.target.value;
		setUpdateForm(obj);
	};

	const handleSave = () => {
		const form = {
			consumerId: updateForm.consumerId,
			retailerId: updateForm.retailerId,
			paymentStatus: updateForm.paymentStatus.toLowerCase(),
			totalAmount: updateForm.totalAmount,
			reason: updateForm.reason,
			id: updateForm.id
		};
		if(validateTransaction(form)){
			if(form.paymentStatus === 'partial' && isEmpty(form.paidAmount)){
				showNotification("warning","Amount Paid cannot be empty for partial payment");
				return;
			}
			if(!checkFloat(form.totalAmount)){
				showNotification("warning","Invalid Total Amount");
				return;
			}
			if(form.paymentStatus === 'partial' && !checkFloat(form.paidAmount)){
				showNotification('Invalid Amount Paid');
				return;
			}
			if(isEmpty(form.reason)){
				showNotification("warning",'Reason cannot be empty');
				return;
			}
			dispatch(transactionUpdate(form));
			navigate("/admin/transactions");
		}
		else{
			showNotification("warning", "Missing Mandatory Field");
		}
	};

	if(!updateForm) return <Loader />;
	return (
		<section className="w-full h-full pt-6 bg-white ">
			<Header title="Edit Transaction" showBackButton={true} to="-1" />
			<div className="w-[40%]  mx-auto px-6 py-14 shadow-2xl rounded flex flex-col space-y-5 bg-white ">
				<div className="flex items-center w-full space-x-4">
					<BsCheck2Circle className="text-4xl" />
					<div className="flex flex-col w-full">
						<label>Payment Status</label>
						<select
							className="w-full px-3 py-2 text-lg border border-gray-400 rounded"
							value={updateForm?.paymentStatus}
							onChange={(e) => handleChange("paymentStatus", e)}
						>
							{options?.map(item => (
								<option key={item.id} value={item.value} className="text-lg">{item.label}</option>
							))}
						</select>
					</div>
				</div>
				<div className="flex items-center w-full space-x-4">
					<BsCashStack className="text-4xl" />
					<div className="flex flex-col w-full">
						<label>Total Amount</label>
						<TextField
							size="small"
							variant="outlined"
							value={updateForm?.totalAmount}
							onChange={(e) => handleChange("totalAmount",e)}
						/>
					</div>
				</div>
				{updateForm.paymentStatus?.toLowerCase() === 'partial' &&
					<div className="flex items-center w-full space-x-4">
						<BsCashStack className="text-4xl" />
						<div className="flex flex-col w-full">
							<label>Amount Paid</label>
							<TextField
								size="small"
								variant="outlined"
								onChange={(e) => handleChange("paidAmount", e)}
								value={updateForm.paidAmount || ""}
							/>
						</div>
					</div>				
				}
				<div className="flex items-center w-full space-x-4">
					<BsCashStack className="text-4xl" />
					<div className="flex flex-col w-full">
						<label>Reason For Update</label>
						<TextField
							size="small"
							variant="outlined"
							onChange={(e) => handleChange("reason", e)}
							value={updateForm.reason || ""}
						/>
					</div>
				</div>
				<div className="flex justify-center space-x-5">
					<button
						className={`${buttonStyle} hover:bg-blue-400`} onClick={handleSave}>
						Save
					</button>
					<button className="px-8 py-2 text-base font-medium text-white bg-red-600 rounded hover:bg-red-400" onClick={() => navigate(-1)}>
						Cancel
					</button>
				</div>
			</div>
		</section>
	);
};

export default TransactionUpdate;
