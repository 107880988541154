import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiEdit2 } from "react-icons/fi";
import { ImBin2 } from "react-icons/im";
import { GoCreditCard } from "react-icons/go";
import { IoImagesOutline, IoStorefrontSharp } from "react-icons/io5";
import { changeProfile, fetchProfile } from "../../redux/features/userSlice";
import { useSelector, useDispatch } from "react-redux";
import { getItem, showNotification } from "../../services/helper";
import { Slide, Dialog, Button as MuiButton, DialogActions, DialogTitle } from "@mui/material";
import Logout from "../Common/Logout";
import { FiPower } from "react-icons/fi";
import { logout } from "../../services/api";

const buttonStyle = "h-8 px-8 font-bold text-white rounded-md";
const inputStyle = "w-[95%] h-10 pl-4 border rounded-lg shadow-inner";
const labelStyle = "ri-content-text";
const sectionStyle = "flex flex-col";
const settingsButtonStyle = "flex font-medium items-center py-4 px-2 justify-start rounded rounded-xl";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProfileSetting = () => {
    const navigate = useNavigate();
    const [view, setView] = useState(1);
    const [open, setOpen] = useState(false);
    const [editData, setEditData] = useState({});
    const [flags, setFlags] = useState([]);
    const dispatch = useDispatch();
    const [deleteUrl, setDeleteUrl] = useState(null);
    const { profile, data } = useSelector(state => state.user);
    const [user, setUser] = useState([]);
    const [confirm, setConfirm] = useState(false);

    useEffect(() => {
        if (!profile) dispatch(fetchProfile(data.retailerId));
        else if (profile) _prepareEditData(profile);
    }, [profile]);

    const _prepareEditData = profileData => {
        const fields = [
            "ownerName", "gender", "email", "panNumber", "bonusScheme", "mobile",
            "storeName", "storeType", "storeCategory", "gstNumber", "location", "district", "state",
            "payeeName", "bankAccountNumber", "bankName", "bankBranch", "bankIFSC",
            "images"
        ]
        const obj = {}, arr = [];
        fields.forEach(field => obj[field] = profileData[field]);
        setEditData(obj);
        profileData?.images?.forEach(() => {
            arr.push(false);
        });
        setFlags(arr);
    }

    const handleChange = (type, e) => {
        e.preventDefault()
        const obj = { ...editData };
        obj[type] = e.target.value;
        setEditData(obj);
    };

    const handleSave = () => {
        dispatch(changeProfile(editData));
        showNotification("success", "Profile Updated");
    };


    const commonSave = () => (
        <div className="flex px-32 pb-8 justify-evenly gap-x-5">
            <button onClick={handleSave} className={`${buttonStyle} bg-blue-400`}>SAVE</button>

        </div>
    );

    const toggleDelete = (index, flag) => {
        const arr = [...flags];
        arr[index] = flag;
        setFlags(arr);
    }

    const handleDeleteDialog = (item) => {
        setOpen(true);
        setDeleteUrl(item);
    }

    const handleDelete = () => {
        let obj = { ...editData };
        const arr = obj.images.filter((item) => item !== deleteUrl);
        setEditData({ ...obj, images: arr });
        dispatch(changeProfile({ ...obj, images: arr }))
        setOpen(false);
    }

    const handleLogout = () => {
        getItem("user")
        logout(user.retailerId, user.token)
        window.localStorage.clear();
        window.location.reload();
        setConfirm(false);

    }


    const view1 = () => (
        <div className="w-[50%] mx-auto flex flex-col py-3 pl-8 space-y-4 ">
            {/* <div className="flex justify-center">
                <button className="hover:bg-blue-500">
                    <img
                        src="/images/1.png"
                        alt="Rounded"
                        className="object-cover object-center rounded-[100%] w-60 h-60"
                    />
                </button>
            </div> */}
            <div className={`${sectionStyle}`}>
                <label className="pb-2 ri-content-text">Owner</label>
                <input
                    type="text"
                    value={editData?.ownerName}
                    className={`${inputStyle}`}
                    onChange={(e) => handleChange("ownerName", e)}
                />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Gender</label>
                <select defaultValue={editData?.gender || ""} className="w-[95%] h-10 border rounded-lg shadow-inner" onChange={e => handleChange("gender", e)}>
                    <option value="MALE">Male</option>
                    <option value="FEMALE">Female</option>
                    <option value="TRANSGENDER">Transgender</option>
                </select>
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Email</label>
                <input
                    type="text"
                    value={editData?.email || ""}
                    className={`${inputStyle}`}
                    onChange={(e) => handleChange("email", e)}
                />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Mobile</label>
                <input
                    disabled
                    type="text"
                    defaultValue={editData?.mobile || ""}
                    className={`${inputStyle}`}
                />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>PAN</label>
                <input
                    type="text"
                    value={editData?.panNumber || ""}
                    className={`${inputStyle} uppercase`}
                    onChange={(e) => handleChange("panNumber", e)}
                />
            </div>

            {editData?.storeType?.toLowerCase() === "electrical & hardware" &&
                <div className={`${sectionStyle}`}>
                    <label className={`${labelStyle}`}>Scheme</label>
                    <input
                        disabled
                        type="text"
                        defaultValue={editData?.bonusScheme || ""}

                        className={`${inputStyle}`}
                    />
                </div>
            }

            {commonSave()}
        </div>
    );

    const view2 = () => (
        <div className="w-[50%] mx-auto flex flex-col pt-6 pl-8 space-y-4 ">
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Store Name</label>
                <input disabled type="text" defaultValue={editData?.storeName || ""} className={`${inputStyle} `} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Store Type</label>
                <input disabled type="text" defaultValue={editData?.storeType || ""} className={`${inputStyle} uppercase`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Store Category</label>
                <input disabled type="text" defaultValue={editData?.storeCategory || ""} className={`${inputStyle} uppercase`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>GST</label>
                <input disabled type="text" defaultValue={editData?.gstNumber || ""} placeholder="Enter GST Number" className={`${inputStyle}`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Location</label>
                <input disabled type="text" defaultValue={editData?.location || ""} className={`${inputStyle} uppercase`} />
            </div>
            <div>
                <label className={`${labelStyle}`}>DISTRICT</label>
                <input disabled type="text" defaultValue={editData?.district || ""} className={`${inputStyle} uppercase`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>State</label>
                <input disabled type="text" defaultValue={editData.state || ""} className={`${inputStyle} uppercase`} />
            </div>
            {commonSave()}
        </div>
    );

    const view3 = () => (
        <div className="w-[50%] mx-auto flex flex-col pt-6 pl-8 space-y-4 ">
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>PAYEE NAME</label>
                <input disabled type="text" defaultValue={editData?.payeeName || ""} className={`${inputStyle}`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>ACCOUNT NUMBER</label>
                <input disabled type="text" defaultValue={editData?.bankAccountNumber || ""} className={`${inputStyle}`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>Bank</label>
                <input disabled type="text" defaultValue={editData?.bankName || ""} className={`${inputStyle}`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>BRANCH</label>
                <input disabled type="text" defaultValue={editData?.bankBranch || ""} className={`${inputStyle}`} />
            </div>
            <div className={`${sectionStyle}`}>
                <label className={`${labelStyle}`}>IFSC</label>
                <input disabled type="text" defaultValue={editData?.bankIFSC || ""} className={`${inputStyle}`} />
            </div>
            {commonSave()}
        </div>
    );

    const view4 = () => (
        <section className="">
            <div className="flex justify-between px-5 py-2">
                <div></div>
                <button className={`px-2 py-1 text-white bg-blue-500 rounded-lg ${editData?.images?.length >= 5 ? "disabled" : ""}`} disabled={editData?.images.length >= 5} onClick={() => navigate("/admin/profile/image")}>Add Image</button>
            </div>
            <div className="grid grid-cols-2 w-[100%] gap-5">
                {editData?.images?.map((item, index) => (
                    <div key={item} onMouseEnter={() => toggleDelete(index, true)} onMouseLeave={() => toggleDelete(index, false)} className="relative">
                        <img src={item} alt="images" className="object-contain object-center w-full" />
                        {
                            flags[index] && <div onClick={() => handleDeleteDialog(item)} className="absolute top-0 right-0 flex items-center justify-center p-2 text-red-500 cursor-pointer bg-gray-700/80"><ImBin2 /></div>
                        }

                    </div>
                ))}
            </div>
        </section>
    );

    return (
        <section className="flex h-full">
            <div className="w-[20%]  border-r-2 bg-[#CFFAFE] sticky">
                <p className="py-5 text-xl font-bold text-center">My Profile</p>
                <div className="px-6 flex flex-col pt-[10%]  rounded-t-md  divide-y-2 divide-slate-300">
                    <button
                        onClick={() => setView(1)}
                        className={`${settingsButtonStyle} ${view === 1 ? " text-blue-600" : ""}`}
                    >
                        <FiEdit2 className="mr-8 text-xl" />
                        MY INFO
                    </button>

                    <button
                        onClick={() => setView(2)}
                        className={`${settingsButtonStyle} ${view === 2 ? " text-blue-600" : ""}`}
                    >
                        <IoStorefrontSharp className="mr-8 text-xl" />
                        STORE
                    </button>
                    <button
                        onClick={() => setView(3)}
                        className={`${settingsButtonStyle} ${view === 3 ? " text-blue-600" : ""}`}
                    >

                        <GoCreditCard className="mr-8 text-xl" />
                        BANK
                    </button>
                    <button
                        onClick={() => setView(4)}
                        className={`${settingsButtonStyle} ${view === 4 ? " text-blue-600" : ""}`}
                    >

                        <IoImagesOutline className="mr-8 text-xl" />
                        IMAGES
                    </button>
                    <button
                        onClick={() => setConfirm(true)}
                        className={`${settingsButtonStyle}  "text-blue-600" : ""}`}
                    >
                        <FiPower className="mr-8 text-xl" />
                        LOGOUT
                    </button>

                </div>
            </div>
            <div className="w-[80%] mx-auto bg-white p-8">
                {view === 1 && view1()}
                {view === 2 && view2()}
                {view === 3 && view3()}
                {view === 4 && view4()}
            </div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to delete?"}</DialogTitle>

                <DialogActions>
                    <MuiButton onClick={() => setOpen(false)}>No</MuiButton>
                    <MuiButton onClick={handleDelete}>Yes</MuiButton>
                </DialogActions>
            </Dialog>
            <>
                <Dialog
                    open={confirm}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => setConfirm(false)}
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle>{"Are you sure you want to logout?"}</DialogTitle>

                    <DialogActions sx={{
                        display: "flex",
                        justifyContent: "center"
                    }}>
                        <div className="flex py-6 space-x-5">
                            <button className='px-2 py-2 font-medium text-white bg-blue-500 rounded ' onClick={handleLogout}> Confirm </button>
                            <button className='px-2 py-2 font-medium text-white bg-red-500 rounded ' onClick={() => setConfirm(false)}> Cancel </button>
                        </div>
                    </DialogActions>

                </Dialog>
            </>
        </section>
    );
};

export default ProfileSetting;
