import React, { useState } from 'react'
import { Slide, Dialog, DialogActions, DialogTitle, Tooltip, Zoom } from "@mui/material";
import { logout } from '../../services/api';
import { getItem, showNotification } from '../../services/helper';
import { useDispatch } from 'react-redux';
import { FiPower } from "react-icons/fi";
import CustomModal from "../CustomModal/CustomModal";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Logout = ({ }) => {
    const [user, setUser] = useState([]);
    const [open, setOpen] = useState(false);

    const hanldeLogout = async () => {
        try {
            getItem("user")
            await logout(user.retailerId, user.token);
            window.localStorage.clear();
            window.location.reload();
            setOpen(true);
        }
        catch (err) {
            console.warn(err);
            showNotification("error", "Unable to Logout!");
        }
    }


    return (
        <>
            <div className='flex justify-between'>
                <div></div>
                <div>

                    <Tooltip title={<h3 className="text-sm">LogOut</h3>} placement="bottom" arrow TransitionComponent={Zoom}>
                        <button className="flex items-center justify-center px-4 py-1 font-semibold text-white duration-500 bg-red-600 rounded hover:bg-red-500" onClick={() => setOpen(true)}> LogOut</button>
                    </Tooltip>
                </div>
            </div>



            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setOpen(false)}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>{"Are you sure you want to logout?"}</DialogTitle>


                <DialogActions sx={{
                    display: "flex",
                    justifyContent: "center"
                }}>
                    <div className='flex py-6 space-x-5'>
                        <button className='px-2 py-2 font-medium text-white bg-blue-500 rounded' onClick={hanldeLogout} >Confirm</button>
                        <button className='px-2 py-2 font-medium text-white bg-red-500 rounded' onClick={() => setOpen(false)} >Cancel</button>
                    </div>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Logout