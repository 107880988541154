import { getDashboardCounts } from "../../services/api";
import { showNotification } from "../../services/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    hasData: false,
    data: {
        transactionCount: { count: 0, totalTransactionAmount: 0 },
        orderCount: { count: 0, totalOrderAmount: 0 }
    },
};

export const dashboardCounts = createAsyncThunk(
    "dashbioard/counts",
    async (retailerId=null, { rejectWithValue }) => {
        try{
            const response = await getDashboardCounts(retailerId);
            if(response.success) return response.result;
            else return initialState.dashboard;
        }
        catch(error) {
            console.warn(error);
            showNotification("error", "Network Error");
            return rejectWithValue(error.response?.data);
        }
    }
);

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: {
        [dashboardCounts.fulfilled]: (state,action) => ({ ...state,hasData:true,data: action.payload, })
    }
});

export const selectDashboard = (state) => state.dashboard;
export default dashboardSlice.reducer;
