import { getMessages,sendMessage } from "../../services/api";
import { showNotification } from "../../services/helper";
import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
	hasData: false,
  	data: {},
};

export const messageAdd = createAsyncThunk(
	"messages/add",
	async (message, { rejectWithValue,dispatch }) => {
		try {
			const response = await sendMessage(message);
			if(response.success){
				dispatch(messagesGet(message.retailerId));
			}
		}
		catch(error){
			console.warn(error);
			return rejectWithValue(error);
		}
	}
);

export const messagesGet = createAsyncThunk(
	"messages/get",
	async (retailerId, { rejectWithValue }) => {
		try{
			const response = await getMessages(retailerId);
			return response.success ? response.result?.list[0] : {};
		}
		catch(error){
			console.warn(error);
			showNotification("error", "Unable to get messages");
			return rejectWithValue(error);
		}
	}
);

const messageSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {},
    extraReducers: {
	[messagesGet.fulfilled]: (state,action) => ({
		...state,
		hasData: true,
		data: action.payload || {},
	}),
  },
})

export const selectAllMessages = (state) => state.messages;
export default messageSlice.reducer
