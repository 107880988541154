import React, { useState } from "react";
import { useDispatch } from "react-redux/es/exports";
import { searchProducts, requestItem } from "../../services/api";
import Header from "../Common/Header"
import { showNotification, debouncer } from "../../services/helper";
import { orderAdd } from "../../redux/features/ordersSlice";
import { useNavigate } from "react-router-dom";
import { sanitizeValue } from "../../services/validation";
import { useEffect } from "react";

const productTextStyle = "font-semibold text-base  mb-2 capitalize";
const productLabelStyle = "text-sm font-medium";
const cardStyle = "bg-white p-5 rounded mb-10 mt-4";
const buttonStyle = "rounded p-2 text-white cursor-pointer duration-300 hover:opacity-80"
const minOrderAmount = 5000;

const OrderAdd = ({ user }) => {
	const { retailerId } = user;
	const [products, setProducts] = useState(null);
	const [selectedProduct, setSelectedProduct] = useState(null);
	const [cart, setCart] = useState([]);
	const [totalAmount, setTotalAmount] = useState(null);
	const [keyword, setKeyword] = useState('');
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleEditCart = (index) => {
		const arr = [...cart];
		arr[index].isEditEnabled = true;
		setCart(arr);
	};

	const handleEditCancelCart = (index) => {
		const arr = [...cart];
		arr[index].isEditEnabled = false;
		setCart(arr);
	}



	const handleQttEdit = (index, e) => {
		let total = 0;
		const arr = [...cart];
		arr[index].itemCount = parseInt(e.target.value) || 0;
		for (let i = 0; i < arr.length; i++) {
			if (i !== index) {
				total = total + parseFloat(arr[i].wholesalePrice * arr[i].itemCount);
			}
		}
		setCart(arr);
		setTotalAmount(total + parseFloat(arr[index].wholesalePrice * arr[index].itemCount));
	}

	const handleSearch = async (keyword) => {
		try {
			if (keyword && keyword.trim().length > 0) {
				setKeyword(keyword);
				const response = await searchProducts(keyword);
				if (response.success) setProducts(response.result?.slice(0, 10));
			}
			else {
				const response = await searchProducts(keyword);
				console.log(response)
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("warning", "Unable to search product");
		}
	};


	const handleSelect = item => {
		setSelectedProduct(item);
		setProducts(null);
	};

	const addToCart = () => {
		if (isNaN(parseInt(selectedProduct.itemCount))) {
			showNotification("warning", "Invalid Quantity");
		}
		else {
			const items = [...cart];
			let index = -1;
			for (let i = 0; i < items.length; i++) {
				if (items[i].id === selectedProduct.id) {
					index = i;
					break;
				}
			}
			if (index > -1) {
				items[index].itemCount += selectedProduct.itemCount;
			}
			else {
				items.push({ ...selectedProduct, isEditEnabled: false });
			}
			setCart(items);
			setSelectedProduct(null);
			setTotalAmount(totalAmount => totalAmount + parseFloat(selectedProduct.wholesalePrice * selectedProduct.itemCount));
		}
	};
	const removeItem = (index) => {
		// const obj = items.filter(items ==> item.id !== editCart.id)
		const arr = [...cart];
		setTotalAmount(totalAmount - parseFloat(arr[index].wholesalePrice * arr[index].itemCount));
		arr.splice(index, 1);
		setCart(arr);
	};

	const clearCart = () => {
		setCart([]);
		setTotalAmount(0);
		setKeyword('');
	};
	const handleUpdateCart = (index) => {
		const arr = [...cart];
		arr[index].isEditEnabled = false;
		setCart(arr);
	};

	const arr = products?.map((item) =>
	(
		<div key={item.id} onClick={() => handleSelect(item)} className="mx-auto hover:scale-105 duration-300 py-2 px-4 rounded border border-gray-400 w-[50%] my-2 text-sm font-medium uppercase bg-white cursor-pointer hover:bg-sky-100">
			{item.name}
			<span className="flex text-xs font-normal" >
				<p className="mr-1">MRP:</p>
				<p>₹{item.mrp}/{item.unit}</p>
			</span>
		</div>
	))


	const placeOrder = () => {
		if (totalAmount < minOrderAmount) {
			showNotification("warning", `Minimum Order Amount is ${minOrderAmount}`);
			return;
		}
		if (cart.length) {
			const body = {
				retailerId,
				items: cart,
				paymentStatus: "pending"
			};
			dispatch(orderAdd(body));
			navigate("/admin/orders");
		}
		else {
			showNotification("warning", "Your Cart is Empty");
		}
	};

	const sendRequest = async () => {
		try {
			if (keyword && keyword.trim().length > 0) {
				const { retailerId, storeName } = user;
				const request = { retailerId, storeName, itemName: keyword };
				const response = await requestItem(request);
				if (response?.success) {
					showNotification("success", `Request sent for ${keyword?.toUpperCase()}`);
				}
				else {
					showNotification("warn", response?.message);
				}
			}
			else {
				showNotification("warn", "Please enter a valid product name");
			}
		}
		catch (err) {
			console.warn(err);
			showNotification("error", "Unable to Send Request");
		}
	};

	return (
		<>
			<div className="p-5">
				<Header title="ADD ORDER" showBackButton={true} to="-1" />
			</div>

			<section className="flex min-w-[70%] justify-around ">
				<section className=" min-w-[40%] max-w-[50%]">

					<div className="flex items-center justify-center space-x-4">
						<p className="w-[40%] px-2 my-2 text-xl font-medium text-left ">SEARCH PRODUCTS</p>
						<input
							type="text"
							onChange={debouncer((e) => handleSearch(e.target.value), 350)}
							className="w-[60%] px-4 py-2 border-gray-500 rounded shadow-md border-1 outline-none"
							placeholder="Enter Product Name"
						/>

					</div>
					<div className="p-6">
						{products?.length === 0 ? (
							<div className="my-4">
								<p className="text-md font-small">Did not find what you are looking for? Place a request here.</p>
								<button
									className="px-3 py-2 mt-3 text-white bg-blue-500 rounded outline-none ml-36 hover:bg-blue-600 focus:bg-blue-600"
									autoFocus
									onClick={sendRequest}
								>
									Request Product
								</button>
							</div>
						) : arr}
						{selectedProduct &&
							<div className={`${cardStyle} flex justify-evenly`}>
								<div>
									<h4 className="py-2 text-lg font-semibold">PRODUCT DETAILS</h4>
									<label className={productLabelStyle}>Name:</label>
									<p className={productTextStyle}>{selectedProduct.name}</p>
									<label className={productLabelStyle}>Category:</label>
									<p className={productTextStyle}>{selectedProduct.category}</p>
									<label className={productLabelStyle}>Brand:</label>
									<p className={productTextStyle}>{selectedProduct.brand || "NA"}</p>
									<label className={productLabelStyle}>Price:</label>
									<p className={productTextStyle}>
										{user?.storeCategory?.toLowerCase() === "rural" ?
											sanitizeValue(selectedProduct.ruralWholesalePrice) : sanitizeValue(selectedProduct.urbanWholesalePrice)}/{selectedProduct.unit}
									</p>
									<label className={productLabelStyle}>MRP:</label>
									<p className={productTextStyle}>{selectedProduct.mrp}/{selectedProduct.unit}</p>
									<label className={productLabelStyle}>Quantity:</label>
									<div className="py-1">
										<input
											type="number"
											onChange={(e) => setSelectedProduct({ ...selectedProduct, itemCount: parseInt(e.target.value) })}
											className="w-[80%] px-4 py-2 border-gray-500 rounded shadow-md border-1 outline-none"
											placeholder="Enter Quantity"
										/>
									</div>
									<div>
										<button className="p-2 mt-5 text-sm font-medium text-white bg-blue-600 rounded" type="button" onClick={addToCart}>Add To Cart</button>
									</div>
								</div>
								<div className="w-[35%] flex flex-wrap">
									{selectedProduct.images?.map((image) => (
										<img key={image} src={image || ""} alt="" className="object-contain object-center" />
									))}
								</div>

							</div>
						}
						{/* {cart?.length > 0 &&
							<div className={`mt-5 ${cardStyle} shadow-md`}>
								<div>
									<p className="mb-3 font-semibold">CART</p>
									<div>
										{cart?.map((item, index) => (
											<div key={index}>
												<label>{item.name}</label>
												<p className="mb-3 font-medium">
													₹ {item.wholesalePrice} x {item.itemCount} = ₹ {(item.wholesalePrice * item.itemCount).toFixed(2)}/-
												</p>
											</div>
										))}
										<p className="mb-3 text-sm font-normal">(Minimum Order: ₹ {minOrderAmount})</p>
										<p className="font-semibold">Total Items: {cart.length}</p>
										<p className="font-semibold">Total Amount: ₹ {totalAmount.toFixed(2)}/-</p>
									</div>
								</div>
								<div className="flex items-center justify-center mt-5 space-x-3">
									<button type="button" className={`${buttonStyle} bg-red-500 hover:bg-red-400 focus:bg-red-700`} onClick={clearCart}>CLEAR CART</button>
									<button type="button" className={`${buttonStyle} bg-blue-500 hover:bg-blue-400 focus:bg-blue-700`} onClick={placeOrder}>PLACE ORDER</button>
								</div>
							</div>
						} */}
					</div>
				</section>
				{cart.length > 0 ?
					<section className="flex w-[30%] px-5 relative py-2 mr-10 -mt-14  ">
						<div className="w-full bg-white rounded shadow-xl min-h-[500px] max-h-[580px]">

							<div className="w-full p-2 border-b ">
								<p className="text-lg font-medium underline">{`CART`}</p>


								<p className="font-semibold">Total Items: {cart.length}</p>
								<p className="font-semibold">Total Amount: ₹ {totalAmount?.toFixed(2)}/-</p>


							</div>
							<div className=" overflow-y-auto min-h-[300px] max-h-[400px]">
								{cart?.map((item, index) => (
									<div key={item.id} className="px-5 py-2 my-3 bg-gray-100 border-b">

										<div className="flex space-x-1">
											<p>{index + 1}.</p>
											<p className="text-base font-medium">{item.name}</p>
										</div>
										<div className="flex justify-between pl-6 pr-10 py-0.5">
											<label className="text-sm">Quantity:</label>
											{cart[index].isEditEnabled ?
												<input className="w-24 px-2 text-base rounded shadow-inner" value={item.itemCount} onChange={(e) => handleQttEdit(index, e)} /> : <p className="text-sm text-gray-500">{item.itemCount} </p>
											}
										</div>
										<div className="flex justify-between px-6">
											<label className="text-sm">Total:</label>
											<p className="text-sm text-slate-600 font-meidum">₹{(item.wholesalePrice * item.itemCount).toFixed(2)}/- </p>
										</div>
										<div className="flex justify-end pt-2 space-x-8">
											{cart[index].isEditEnabled ?
												<div className="flex justify-center space-x-8">
													<button className="px-2 py-1 text-sm font-medium text-white bg-red-500 rounded" onClick={() => handleEditCancelCart(index)}> Cancel</button>
													<button className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded" onClick={() => handleUpdateCart(index)}>Confirm </button>
												</div>

												: <div className="flex justify-center space-x-8">
													<button className="px-2 py-1 text-sm font-medium text-white bg-red-500 rounded" onClick={() => removeItem(index)}> Remove</button>
													<button className="px-2 py-1 text-sm font-medium text-white bg-blue-500 rounded" onClick={() => handleEditCart(index)}> Edit</button>
												</div>
											}
										</div>


									</div>
								))}
							</div>

							<div className="flex items-center justify-center py-4 space-x-3">
								<button type="button" className={`${buttonStyle} bg-red-400 hover:bg-red-600 focus:bg-red-700`} onClick={clearCart}>CLEAR CART</button>
								<button type="button" className={`${buttonStyle} bg-blue-400 hover:bg-blue-600 focus:bg-blue-700`} onClick={placeOrder}>PLACE ORDER</button>
							</div>

						</div>
					</section> : ("")}

			</section>
		</>
	);
};

export default OrderAdd;

