import {
	addOrder,
	getAllOrders
} from "../../services/api";
import { showNotification } from "../../services/helper";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
	hasData: false,
	data: [],
};

export const orderAdd = createAsyncThunk(
	"orders/orderAdd",
	async (order, { rejectWithValue, dispatch }) => {
		try {
			const response = await addOrder(order);
			if (response.success) {
				showNotification("success", "New order added");
				await dispatch(ordersGet({ retailerId: order.retailerId }));
			}
		}
		catch (error) {
			console.log(error);
			return rejectWithValue(error.response?.data);
		}
	}
);

export const ordersGet = createAsyncThunk(
	"orders/ordersGet",
	async (params, { rejectWithValue }) => {
		try {
			const { retailerId, offset, limit, startDate, endDate } = params;
			const response = await getAllOrders(retailerId, offset, limit, startDate, endDate);
			return response.success && response.result ? response.result : {};
		}
		catch (error) {
			console.warn(error);
			return rejectWithValue(error.response?.data);
		}
	}
);

const ordersSlice = createSlice({
	name: "orders",
	initialState,
	reducers: {},
	extraReducers: {
		[ordersGet.fulfilled]: (state, action) => (
			{ ...state, hasData: true, data: action.payload, }
		),
	},
});

export const selectAllOrders = (state) => state.orders;
export default ordersSlice.reducer;
