import React from 'react';
import GoBack from './GoBack';
import Logout from './Logout';
import Reload from './Reload';

const Header = ({ title,showBackButton,to,showReload,showLogout,func }) => (
	<div className='relative flex items-center justify-between w-full mb-3 overflow-visible'>
		<div className='flex items-center'>
			{showBackButton && <GoBack to={to} />}
			<h3 className='text-xl font-semibold'>{title}</h3>
			{showReload && <Reload func={func} />}
		</div>

			{showLogout && <Logout  />}
	</div>
);

export default Header;